.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}

.head_view {
  font-size: 16px;
  display: flex;
}

.arrow_icon {
  width: 8px;
  margin: 0px 10px 0;
}
.arrow_seeall {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow_icon {
  margin: 0px 10px 0 !important;
}
.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.courses_cat_list {
  margin-bottom: 40px;
  text-align: center;
}

.courses_cat_list .active {
  color: #ffffff;
  border-color: transparent;
  background-color: #006d77;
}

.courses_cat_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: auto;
  font-size: 18px;
  border: 1px solid #000000;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
}

.courses_cat_box:active {
  color: #ffffff;
  border-color: transparent;
  background-color: #006d77;
}

.courses_card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.no-record {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

div.scroll {
  margin: 4px, 4px;
  padding: 4px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

div.scroll::-webkit-scrollbar {
  height: 5px;
}

div.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

div.scroll::-webkit-scrollbar-thumb {
  background: #ccc;
}

div.scroll::-webkit-scrollbar-thumb:hover {
  background: #006d77;
}

.filter_departments_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }
  .head_view {
    font-size: 14px;
  }
  .courses_cat_box {
    font-size: 16px;
    height: 45px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }
  .courses_cat_box {
    font-size: 14px;
    height: 40px;
  }
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

@media (max-width: 767px) {
  .courses_cat_box {
    font-size: 12px;
    height: 40px;
    margin-bottom: 20px;
  }
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}
