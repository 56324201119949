/* h1,
h2,
h3,
h4,
h5,
span,
p {
	z-index: 500;
} */

.cover-container {
	position: relative;
	text-align: center;
	color: white;
}

.cover-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: transparent url(dummyImages/overlay.png) no-repeat center;
}

.bottom-right {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	bottom: 8px;
	right: 16px;
	width: 100%;
}

.booth_image {
	width: 152px;
	height: 152px;
}

.general-info {
	margin-right: 32px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	width: 100%;
}

.booth_size-info-badge {
	width: 193px;
	height: 37px;
	color: #ffffff !important;
	background-color: #24b3b9;
	border-radius: 38px;
	padding: 4px 22px;
	margin-right: 10px;
}

.booth_size-info {
	margin: 0;
}

.booth-desc-head {
	font-size: 24px;
	font-weight: bold;
	color: #046c77;
}

.booth-desc-parag {
	font-size: 18px;
	font-weight: 400;
	color: #7b7b7b;
}

.booth-main-audio-file {
	font-size: 16px;
	font-weight: bold;
	color: #777777;
	margin-bottom: 18px;
}

.booth-banners-head {
	font-size: 24px;
	font-weight: bold;
	color: #777777;
	margin-bottom: 35px;
}

.filter-icon {
	width: 50px;
	height: 50px;
	padding: 15px;
	border: 2px solid #24b3b9;
	border-radius: 6px;
}

.pdf-icon {
	width: 50px;
	height: 50px;
	padding: 15px;
	border-radius: 6px;
	background-color: #b91414;
}

/*  */

.review-details__carousel {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding-top: 50px;
	padding-bottom: 50px;
}

.review-details__carousel-title {
	color: #777777;
	font-size: 20px;
}

.exhibition-details__audios-item {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 50%;
}

.exhibition-details__audios-item-title {
	font-size: 16px;
	color: #777777;
}

.exhibition-details__audios-item audio {
	width: 100%;
}

@media only screen and (max-width: 768px) {
	.exhibition-details__audios-item {
		width: 100%;
	}
}
