.wrapper-div {
  position: relative;
}

.header-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.badge-postion {
  position: absolute;
}
