.tajah-form-row-attachment-element {
	position: relative;
	width: 100%;
	height: 150px;
	border-style: dashed;
	border-width: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fbfbfb;
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
}

.tajah-attachment-element-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tajah-attachment-element-text-style {
	font-size: 14px;
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}

.add-ticket {
	margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
	.add-ticket__form-group {
		flex-wrap: wrap;
		gap: 0;
	}

	.add-ticket__form-group .tajah-form-row-element {
		flex: 1 1 100%;
	}

	.add-ticket .tajah-form-container {
		padding: 20px;
	}
}
