.trainers-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  /* grid-auto-rows: minmax(100px, auto); */
}

.flexcard {
  /* display: flex;
  justify-content: center; */
  /* min-height: 300px; */
}

@media (max-width: 1279px) {
  .trainers-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1023px) {
  .trainers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .trainers-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
