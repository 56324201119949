.main {
  width: 100%;
  display: flex !important;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 72vh;
}
.backdropDiv {
  display: flex !important;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(3, 162, 178, 0.15) 45%,
    rgba(3, 151, 178, 0.3) 63%,
    rgba(3, 145, 178, 0.45) 81%,
    rgba(3, 145, 178, 0.65) 96%
  );
  direction: rtl;
}
body[dir="ltr"] .backdropDiv {
  direction: ltr;
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 60%;
  margin-left: 20px;
}

.main__content {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
body[dir="rtl"] .main__content {
  text-align: start;
}

.btnRegister {
  padding: 10px 50px;
  font-size: 16px;
  color: rgb(3, 162, 178) !important;
}

.main__content-title {
  font-size: 3rem;
  font-weight: 500;
  color: rgb(3, 108, 119) !important;
}

.main__content-desc {
  line-height: 2rem;
  font-size: 1.5rem !important;
  margin-bottom: 10px;
  color: rgb(75 85 99);
}

.search_container {
  display: flex;
  gap: 20px;
  width: 80%;
}

.main__mobile {
  display: none;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: #ffffff;
}

@media (max-width: 1200px) {
  .main__mobile {
    width: 100%;
    height: 80vh;
    display: flex !important;
  }

  .main {
    display: none !important;
  }

  .backdropDiv {
    justify-content: center;
  }
  .main__wrapper {
    justify-content: center;
  }
  .main__content {
    align-items: center;
    text-align: center;
  }
  .main__content-desc {
    font-size: 1.25rem !important;
  }
  .main__content-title {
    font-size: 2.25rem;
  }
  .search_container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .main__content-desc {
    font-size: 0.8rem !important;
  }
  .main__wrapper {
    width: 100%;
  }
}
