.profile-container {
  margin: 1rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.profile-item-box {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 150px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

.profile-item-box:hover {
  background-color: #d9d9d9;
  border: 1px solid #555;
}

.profile-icon {
  width: 2rem;
  height: 2rem;
}

.profile-icon img {
  width: 100%;
  height: 100%;
}

.profile-name {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.profile-name img {
  width: 1.2rem;
  height: 1.2rem;
}

body[dir="rtl"] .profile-name img {
  transform: rotate(180deg);
}

.profile-description {
  font-size: 0.8rem;
  font-weight: 500;
  color: #777777;
  margin-top: 0.5rem;
}

.card-counter {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px #ddd;
  cursor: pointer;
}

.card-counter:hover {
  background-image: linear-gradient(to bottom, #fff, rgba(3, 108, 119, 0.1));
}

.link-cursor {
  cursor: pointer;
}

.card-top {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-image {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 10px;
}

.card-image img {
  width: 100%;
  height: 100%;
}

.card-label {
  font-size: 14px;
  color: rgb(3, 108, 119);
  font-weight: 600;
}

.card-number {
  font-size: 1.6rem;
  font-weight: bold;
  color: rgb(20, 148, 154);
  margin-bottom: 10px;
}

.card-link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: rgb(3, 108, 119);
}

.arrow {
  width: 10px;
  height: 10px;
  margin-top: 5px;
}

.arrow img {
  width: 100%;
  height: 100%;
}
