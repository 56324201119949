.MainSectionCarousel .slick-dots {
  /* position: unset !important; */
  /* display: flex !important; */
  width: 100%;
  padding: 15px 0;
  /* margin-top: -50px; */
  text-align: center;
}
.slick-dots li.slick-active button:before {
  opacity: unset;
  color: #24b3b9;
}
.slick-dots li button:before {
  font-size: 10px;
  color: white;
  opacity: 0.8;
}

.MainSectionCarousel .slick-next {
  left: unset !important;
  right: 30px !important;
}

.MainSectionCarousel .slick-prev {
  right: unset !important ;
  left: 20px !important;
}

.MainSectionCarousel .slick-arrow {
  top: 50% !important;
}

body[dir="rtl"] .MainSectionCarousel .slick-prev {
  right: unset !important ;
  left: 20px !important;
}
body[dir="rtl"] .MainSectionCarousel .slick-prev::before {
  content: "←";
}

body[dir="rtl"] .MainSectionCarousel .slick-next {
  left: unset !important;
  right: 30px !important;
}

body[dir="rtl"] .MainSectionCarousel .slick-next::before {
  content: "→";
}
@media (max-width: 500px) {
  .MainSectionCarousel .slick-arrow {
    top: 90% !important;
    z-index: 9;
  }
}
.MainSectionCarousel .slick-arrow.slick-prev {
  transform: none;
  z-index: 9;
}
.MainSectionCarousel .slick-arrow.slick-next {
  transform: none;
}
.MainSectionCarousel .slick-arrow.slick-next::before {
  color: #ffffff4d;
  font-size: 40px;
}
.MainSectionCarousel .slick-arrow.slick-prev::before {
  color: #ffffff4d;
  font-size: 40px;
}
