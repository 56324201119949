.courses_card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.courses_card_header {
}

.no_courses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
  grid-column: 1 / -1;
}

.no_courses_icon {
  width: 70px;
  height: 70px;
}

.no_courses_icon img {
  width: 100%;
  height: 100%;
}

.courses_card_header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #24b3b9;
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}
