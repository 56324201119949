.mainCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin-bottom: 60px;
  padding: 1rem 0;
  width: 300px;
}
.mainCardMargin {
}
@media (min-width: 1268px) {
  .mainCardMargin {
    margin-inline-start: 50px;
  }
}
.subCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  height: 160px;
  gap: 1rem;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.subCard img {
  width: 50px;
  height: 50px;
}
.subCard h2 {
  font-size: 14px !important;
  font-weight: 500 !important;
  max-width: 70%;
  text-align: center;
}
