.trainersSection {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  gap: 1rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.trainersSectionCont {
  /* margin: 2rem 0; */
  padding: 4rem 0;
  background: #f1f1f1;
}

.trainersSection h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.trainersSection p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.trainersSection .slider {
  width: 100%;
  display: flex;
  gap: 1rem;
  min-height: 34rem;
  overflow-x: auto;
  padding: 2rem 0;
}
.sectionTitle {
  color: #006d77;
}
.sectionTitle span {
  color: #83c5be;
}
.staticCont {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 3rem;
}
