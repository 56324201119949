.auth_card_social_container {
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;
  min-height: 12rem;
  padding: 20px 40px;
  border-radius: 4px;
  position: relative;
}

.auth_card_social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 0 10px;
}
.auth_logo {
  width: 90px;
}

.auth_social_title {
  font-size: 16px;
  color: #046c77;
  font-weight: bold;
  margin-bottom: 0;
}

.auth_social_label {
  font-size: 13px;
  color: #000;
  position: absolute;
  bottom: 20px;
}
.auht_social_login {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.auth_facebook,
.auth_twitter,
.auth_linkedin,
.auth_gmail {
  border-width: 0px;
  width: 100%;
  height: 40px;
  padding: 7px 0;
  cursor: pointer;
  margin: 10px 0px;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #ccc;
}

.auth_email {
  border-radius: 5px;
  width: 50%;
  height: 45px;
  padding: 7px 0;
  cursor: pointer;
  margin: 10px 0 0 0;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000 !important;
}

body[dir="rtl"] .auth_facebook,
body[dir="rtl"] .auth_linkedin {
  margin-left: 0px;
  margin-right: 0px !important;
}

.col-md.auth-twitter {
  padding: 0;
}
.col-md.auth-twitter i {
  font-size: 17px;
  padding: 5px;
  transform: translate(0px, 4px);
}

.icon_soical {
  width: 20px;
  height: 20px;
}

.icon_flex {
  display: flex;
  flex-direction: row-reverse;
}
body[dir="rtl"] .icon_flex {
  display: flex;
  flex-direction: row;
}
.auth_email_logo {
  width: 60px;
  padding: 0 11px;
}
.auth_soical {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(132, 132, 132, 0.2);
  padding: 25px 25px 0 25px;
  border-radius: 15px;
  position: absolute;
  width: 325px;
  min-height: 400px;
  left: 480px;
}

body[dir="rtl"] .auth_soical {
  right: 450px;
}

@media (max-width: 991px) {
  .auth_logo {
    width: 70px;
  }
  .auth_soical {
    left: 410px;
  }
  body[dir="rtl"] .auth_soical {
    right: 410px;
  }
}

@media (max-width: 767px) {
  .auth_card_social_container {
    padding: 0 15px;
    border-radius: 0;
  }

  .auth_card_social {
    padding: 0;
  }

  .auth_social_label {
    margin: 10px 0;
  }

  .auht_social_login {
    width: 100%;
  }

  .auth_facebook,
  .auth_twitter,
  .auth_linkedin,
  .auth_gmail,
  .auth_email {
    font-size: 15px;
    width: 100%;
  }
  .auth_email_logo {
    width: 50px;
  }
  .auth_soical {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
    left: 0;
    padding: 10px 45px;
  }
  body[dir="rtl"] .auth_soical {
    right: 0px;
  }
}
