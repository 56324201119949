.row.tabs.topTab {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.test span {
  /* border-top: 2px solid #bdbdbd !important;
  border-radius: 20px; */
}


.active--1 .content-span {
  color: #126e77;
}

.col--2 {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.css-1bw0nnu-MuiStep-root {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 2px
}


.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  display: flex;
  /* flex-direction: column !important; */
}
.css-ascpo7-MuiStepLabel-root {
  flex-direction: column !important;
}
.css-1hv8oq8-MuiStepLabel-label {
  font-size: 18px !important;
  font-family: dinNextRegular, sans-serif !important;
}

/*
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #126e77 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #046C77 !important;
  position: relative;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #046C77 !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color: #046C77 !important;
  font-weight: 600;
}

.css-j5w0w9-MuiStepConnector-root {
  margin-bottom: 50px;
}

.css-vnkopk-MuiStepLabel-iconContainer {
  margin-bottom: 25px;
} */