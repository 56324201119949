/**  Zoom **/
.booth-header-txt-h3 {
  font-size: 33px;
  font-weight: normal;
  color: #046c77;
  display: inline;
}

.booth-header-txt-span {
  color: #24b3b9;
  font-size: 21px;
  font-weight: 300;
}

.admin_label {
  font: 18px;
  display: flex;
  margin: 30px 0;
}

.delete-modal {
  display: flex;
  width: 100%;
  height: 200px;
  position: fixed;
  z-index: 1000000000;
  justify-content: center;
  align-items: center;
  top: 50%;
}

.delete-modal-fix-top {
  top: calc(50% - 100px);
}

.delete-modal-button {
  width: 70px;
  padding: 5px 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.delete-box {
  display: flex;
  flex-direction: column;
  padding: 35px 50px;
  justify-content: space-between;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.delete-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.delete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000000;
  background: rgba(0, 0, 0, 0.3);
}

.fix_delete_style {
  top: 40%;
  width: 90%;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 40px; */
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.font_awesome_edit {
  font-size: 20px !important;
  color: #000;
}

.admin_button_green,
.admin_button_green:active,
.admin_button_green:hover {
  display: unset;
  justify-content: center;
  align-items: center;
  min-width: 130px !important;
  padding: 10px 30px;
  height: 40px;
  background-color: #006d77;
  color: #fff !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_button_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin_button_flex span {
  padding: 0 5px;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 40px; */
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_box_bg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
}

.admin_input_sponsor {
  display: flex;
  width: 90%;
  padding: 0px 0;
  align-items: unset !important;
}

.delete_coupon {
  width: 65%;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 40px; */
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_card_add {
  min-height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_card .table {
  width: 90%;
  margin: auto;
}

.admin_label_card {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.release_icon .fa-trash-alt {
  color: #d20101;
}

.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin_add_label {
  font-size: 14px;
  color: #24b3b9;
}

.admin_add_input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  /* padding: 0 15px; */
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50px;
}

.admin_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_edit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.admin_box_text {
  font-size: 30px;
  margin-bottom: 30px;
  color: rgb(0, 0, 0, 47%);
}

/* time */
.time_use .react-datepicker__month-container,
.time_use
  .react-datepicker__navigation
  .react-datepicker__navigation--next
  .react-datepicker__navigation--next--with-time {
  display: none !important;
}

.time_use
  .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
  display: none !important;
}

.time_use .react-datepicker__navigation.react-datepicker__navigation--previous {
  display: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: unset !important;
}

/* time */
.admin_coupon_in_card {
  display: flex;
  background-color: #ebebeb;
  padding: 10px;
  text-align: center;
  justify-content: space-between;
  margin: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.coupon_card_width {
  min-width: 20%;
}

.admin_coupon_radio {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  /* width: 40%; */
}

.delete_icon {
  cursor: pointer;
  margin: 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.release_icon .fa-trash-alt {
  color: #d20101;
}

@media (max-width: 1500px) {
  .delete_coupon {
    width: 90%;
  }
}

@media (max-width: 991px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 16px !important;
  }

  .admin_label_card {
    flex-direction: column;
    /* height: 150px; */
  }
}

@media (max-width: 768px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 14px !important;
  }

  .overview_button {
    width: 100%;
  }

  .data-rate,
  .card-buttons {
    flex-direction: column;
    gap: 8px;
  }

  .card-buttons {
    align-items: flex-start;
  }
}

.admin_label {
  display: flex;
  margin: 30px 0;
}

.table_bank_tran {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px auto 40px;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.table_bank_tran .table {
  width: 100%;
}

.label_bank_tran {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.admin_label_card {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.select_admin {
  width: 150px;
  height: 40px;
  border: none;
  padding: 0 10px;
  background-color: #eee;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table_bank_tran td,
.table_bank_tran th {
  text-align: center;
}

.table_bank_tran i {
  color: #006d77;
  font-size: 13px;
  cursor: pointer;
}

.backdrop_table_bank_tran {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.8);
}

.table_bank_tran button i {
  font-size: 17px;
  color: #fff;
  padding: 0px 5px;
}

.table_bank_tran button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Admin Role */
.admin-role {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.admin-role-input {
  width: 30%;
  /* height: 100%; */
  /* position: absolute; */
  /* left: 145px; */
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 0 10px;
}

.admin-role-search {
  border: 0px;
  outline: none;
  width: 90%;
  height: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Admin Role */
.modal_flex_card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_card_admin {
  z-index: 1001 !important;
  position: fixed;
  top: calc(35% - 100px);
  width: 500px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  z-index: 100;
  overflow: auto;
  animation: dropmove 0.5s normal;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  -webkit-animation: dropmove 0.5s normal;
}

.admin_edit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.custom_select {
  position: relative;
  display: inline-block;
  width: 130px;
}

@media (max-width: 991px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 14px !important;
  }

  .admin-header-table {
    flex-direction: column !important;
  }

  .admin-role-input,
  .custom_select {
    width: 50% !important;
  }

  .modal_card_admin {
    width: unset !important;
  }
}

.list_of_trainer {
  padding: 25px 0;
}

.list_of_trainer .btn_flex {
  display: flex;
  justify-content: flex-end;
}

.sponsor_model {
  align-items: baseline;
  padding: 0px 30px;
  height: 315px;
  padding-bottom: 40px;
}

.sponsor_model p {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.sponsor_model .admin_add_button {
  margin: 0 8px;
}

.remove_doc {
  color: #d20101;
  position: absolute;
  right: 0;
  top: 6px;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 15px;
  font-weight: 400;
  border: none;
}

body[dir="rtl"] .remove_doc {
  right: unset;
  left: 0;
}

.remove_doc i {
  color: #d20101 !important;
  font-size: 15px;
}

@media (max-width: 768px) {
  .sponsor_model p {
    width: 100%;
  }
}

/** End Zoom**/

.checkbox_zoom {
  height: 17px;
  width: 17px;
  transform: translate(0px, 5px);
}

.parg_zoom {
  display: inline-block;
  padding: 0 10px;
  margin: 0;
}

.admin_add_button.btn_zoom {
  /* margin: 40px 0 0 0; */
}

.span-input {
  position: absolute;
  bottom: 47px;
  right: 20px;
  color: #918f8f;
}

body[dir="rtl"] .span-input {
  left: 20px;
  right: unset;
}

.admin_input_sponsor input::-webkit-outer-spin-button,
.admin_input_sponsor input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.admin_input_sponsor input[type="number"] {
  -moz-appearance: textfield;
}

.add-audio {
  padding: 20px;
  border: 1px solid #cdcdcd;
  border-style: dashed;
  border-radius: 10px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.add-audio i {
  background: #006d77;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  width: 30px;
  bottom: -13px;
  transform: translate(-19px, 0px);
  height: 30px;
  text-align: center;
  font-size: 13px;
}

body[dir="rtl"] .add-audio i {
  transform: translate(19px, 0px);
}

.msg-audio {
  font-size: 13px;
  color: #333;
  padding: 0px 20px;
  transform: translate(0px, 10px);
}

.input-upload-audio {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 22;
  left: 0;
  right: 0;
}

/* .admin_add_label{
    z-index: 55;
    position: relative;
} */
.delete-upload-file,
.delete-upload-file:active,
.delete-upload-file:hover {
  color: #cc0000;
  border: none;
  background: none;
  padding-top: 35px !important;
}

.delete-upload-file i {
  color: #cc0000 !important;
}

.table_header {
  padding: 0 0 20px 0;
}

/* Start booth */
.active-text {
  font-weight: 700;
  color: #cc0000;
}

.position-ui {
  text-align: center;
  min-height: 140px;
  margin-bottom: 20px;
  padding: 30px 10px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  cursor: pointer;
  font-size: 18px;
  width: calc(96% / 4);
}

@media screen and (max-width: 1200px) {
  .position-ui {
    width: calc(96% / 3);
  }
}

@media screen and (max-width: 800px) {
  .position-ui {
    width: calc(96% / 2);
  }
}

@media screen and (max-width: 435px) {
  .position-ui {
    width: 100%;
  }
}

.position-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #eee;
  height: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 7px;
  font-size: 17px;
}

.position-opacity {
  opacity: 0;
}

.active-position {
  color: #fff;
  background-color: #24b3b9 !important;
}

.approved-position {
  color: #fff;
  background-color: #f55858 !important;
}

.not-approved-position,
.Position-not {
  color: #fff;
  background-color: #ff9300 !important;
}

.position-box {
  border: 1px solid #ddd;
  margin-top: 15px;
  padding: 30px;
  border-radius: 7px;
  gap: 12px;
  /* justify-content: space-between; */
}

.position-nots {
  display: flex;
  justify-content: flex-end;
}

.position-nots .available {
  padding: 6px 7px;
  background: #eee;
  margin: 10px;
}

.position-nots .unavailable {
  padding: 6px 7px;
  background: #f55858;
  margin: 10px;
}

.position-nots .unavailable-not-completed {
  padding: 6px 7px;
  background: #ff9300;
  margin: 10px;
}

.hide {
  display: none !important;
}

.tabs {
  padding-top: 70px;
  padding-right: 20px;
  padding-left: 85px;
  padding-bottom: 70px;
}

body[dir="rtl"] .tabs {
  padding-left: 20px;
  padding-right: 85px;
}

.tabs .tab {
  border-top-width: 3px;
  border-top-color: #e0e0e0;
  border-top-style: solid;
  padding: 0;
  padding-top: 10px;
}

body[dir="rtl"] .tabs .tab .content-span {
  right: -3px;
  left: unset;
}

.tabs .tab .content-span {
  text-align: center;
  display: block;
  position: absolute;
  font-size: 13px;
  left: -29px;
  bottom: -50px;
}

.active--1 .tab:nth-of-type(1) {
  border-top-color: #126e77;
}

.tabs .tab:nth-of-type(2):after {
}

body[dir="rtl"] .tabs .tab:after {
  right: 0;
  left: unset;
}

.tabs .tab:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  left: 0;
  top: -7px;
  border: 1px solid #126e77;
}

.tabs .tab:nth-of-type(5) {
  border: none;
}

/* mohammed start */

.tabs.active--2 .tab:nth-of-type(2) {
  border-top-color: #126e77;
}

.tabs.active--2 .tab:nth-of-type(2):after {
  background: #126e77;
}

.tabs.active--3 .tab:nth-of-type(2) {
  border-top-color: #126e77;
}

.tabs.active--3 .tab:nth-of-type(2):after {
  background: #126e77;
}

.tabs.active--3 .tab:nth-of-type(3) {
  border-top-color: #126e77;
}

.tabs.active--3 .tab:nth-of-type(3):after {
  background: #126e77;
}

.tabs.active--4 .tab:nth-of-type(2) {
  border-top-color: #126e77;
}

.tabs.active--4 .tab:nth-of-type(2):after {
  background: #126e77;
}

.tabs.active--4 .tab:nth-of-type(3) {
  border-top-color: #126e77;
}

.tabs.active--4 .tab:nth-of-type(3):after {
  background: #126e77;
}

.tabs.active--4 .tab:nth-of-type(4) {
  border-top-color: #126e77;
}

.tabs.active--4 .tab:nth-of-type(4):after {
  background: #126e77;
}

.tabs.active--5 .tab:nth-of-type(2) {
  border-top-color: #126e77;
}

.tabs.active--5 .tab:nth-of-type(2):after {
  background: #126e77;
}

.tabs.active--5 .tab:nth-of-type(3) {
  border-top-color: #126e77;
}

.tabs.active--5 .tab:nth-of-type(3):after {
  background: #126e77;
}

.tabs.active--5 .tab:nth-of-type(4) {
  border-top-color: #126e77;
}

.tabs.active--5 .tab:nth-of-type(4):after {
  background: #126e77;
}

.tabs.active--5 .tab:nth-of-type(5) {
  border: none;
}

.tabs.active--5 .tab:nth-of-type(5):after {
  background: #126e77;
}

iframe {
  width: 100%;
  height: 650px;
  margin-bottom: 40px;
}

/* ############# new style */

.breadcrumbs-my-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

@media (max-width: 1280px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.border-8 {
  border-radius: 8px;
}

.cards-details {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sub-details {
  padding: 0 10px;
}

.data-rate {
  display: flex;
  /* align-items: center; */
  gap: 4px;
}

.star-font-size::before {
  font-size: 1rem !important;
}

.flip-star {
  transform: rotateY(180deg);
}

.card-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 20px;
}

.sub-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sub-buttons-column {
  flex-direction: column;
}

.no-padding {
  padding: 0 !important;
}

.icon-w {
  width: 39px;
}

.justify-center {
  justify-content: center;
}

.actions-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

.skeleton-card {
  height: 200px;
}

.w-60 {
  width: 60%;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 250px;
  grid-column: 1 / 4;
}

.no-data span {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

@media (max-width: 1280px) {
  .w-60 {
    width: 80%;
  }
}

.modal-buttons {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.modal-buttons button {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.sm-font-size {
  font-size: 12px;
}

.sponsors-slider-item {
  text-align: center;
}

.sponsors-slider-item-image {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.sponsors-slider-item-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-header-without-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-width {
  width: 35% !important;
}

.button-font-size {
  font-size: 14px;
}

.small-icon img {
  width: 15px;
  height: 18px;
}

.pointer {
  cursor: pointer;
}

.m-50 {
  margin: 50px 0;
}
.py-5 {
  padding: 20px 0;
}

.btn-back {
  border: 1px solid #24b3b9;
  padding: 10px;
  display: inline-block;
  padding: 20px;
  background-color: red;

  /* color: "#333",
    marginRight: 20,
    background: "#CFCFCF",
    borderRadius: 6,
    border: "none",
    padding: "10px 40px",
    display: "inline-block", */
}

.dashes-line {
  width: 40%;
  border-bottom: 1px dashed black;
  margin: 10px 0;
}

/*  */

.custom-small-badge {
  position: absolute;
  bottom: 10px;
  right: 15px;
  padding: 5px 15px;
  color: #fff;
  border-radius: 8px;
  left: unset;
  top: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.custom-small-badge img {
  width: 15px;
}

.exhibition-card {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .add-product-action {
    justify-content: center !important;
  }
  .add-product-action .dashes-line {
    display: none;
  }

  .sub-buttons-column {
    flex-direction: row;
  }

  .booth-header-txt-h3 {
    font-size: 1.5rem;
  }
}
