.user-card {
  background-color: #ffffff00;
  /* padding: 10px; */
  /* border-radius: 10px; */
  padding-bottom: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  /* width: 100%; */
  /* border: 1px solid #b7b7b77a; */
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  justify-content: space-between;
}
.imgCont {
  height: 230px;
  width: 320px;
  border-bottom: 10px solid #4e8284;
  cursor: pointer;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 10px; */
}
.cardContantNew {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  gap: 5px;
}

.sapretor {
  height: 3px;
  margin: 5px 0;
  margin-top: 10px;
  background: linear-gradient(
    270deg,
    rgba(138, 140, 140, 0.4767156862745098) 26%,
    rgba(233, 229, 229, 0.514) 68%
  );
}
body[dir="ltr"] .sapretor {
  background: linear-gradient(
    270deg,
    rgba(233, 229, 229, 0.514) 26%,
    rgba(138, 140, 140, 0.4767156862745098) 68%
  );
}

.job_title p {
  /* text-align: center; */
  font-size: 12px !important;
  color: #959595;
  font-weight: 600;
  margin-top: 10px;
  /* height: 50px; */
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* justify-content: space-between; */
  margin-bottom: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 320px;
  height: 400px;
  background-color: rgba(247, 247, 247, 1);
}
@media (min-width: 1268px) {
  .content {
    margin-inline-start: 50px;
  }
}

.hovering {
  transform: scale(1.05);

  transition: 500ms ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.num_courses {
  display: flex;
  gap: 15px;
  align-items: center;
  /* background-color: #75afb32f; */
  /* padding: 10px 25px; */
  /* border-radius: 20px; */
  /* max-width: 200px; */
  /* justify-content: center; */
  color: #000;
  font-weight: 900;
  /* margin: 0 auto; */
  margin-top: 5px;
  font-size: 14px;
}
.numContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #488b85;
  font-weight: bold;
  white-space: nowrap;
  font-size: 10px;
}
.btnCont {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}
.aboutButton {
  width: 100%;
  display: flex;
  justify-content: center;
}
