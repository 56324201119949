.tajah-form-flex-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tajah-form-flex-holder:not(:first-child) {
    margin-top: 15px;
}

.tajah-form-flex-holder-container {
    width: 60%;
}

.tajah-rating-box {
    padding: 30px;
    border: #B9B9B9 1px solid;
    border-radius: 5px;
}

.MuiSlider-colorPrimary {
    color: #006d77 !important;
}

.MuiSlider-thumbColorPrimary {
    color: #006d77;
}

.tajah-rating-icon-holder {
    margin-right: 20px;
}

body[dir="rtl"] .tajah-rating-icon-holder {
    margin-left: 20px;
    margin-right: 0px;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 150px;
}

