.sectionTitle {
  color: #006d77;
}
.sectionTitle span {
  color: #83c5be;
}
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 2rem 0; */
  gap: 1rem;
  padding: 0.5rem 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.categories h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.categories p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.slider {
  width: 100%;
  display: flex;
  gap: 1rem;
  min-height: 20rem;
  overflow: none;
  padding: 2rem 0;
}
.trainersSectionCont {
  /* margin: 2rem 0; */
  padding: 4rem 0;

  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 42%,
    rgba(230, 242, 242, 1) 84%,
    rgba(219, 238, 239, 1) 100%
  );
}
.aboutButtonCont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.aboutButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.staticCont {
  display: flex;
  gap: 5rem;
  justify-content: center;
  margin-top: 3rem;
}
