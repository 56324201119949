.items__container__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.items-label {
  color: #777777;
}

.items__container {
  display: flex;
  align-items: center;
  height: 50px;
}

.items__container input {
  border: 1px dashed #ebebeb;
  height: 100%;
  padding: 0 1rem;
  flex: 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #777777;
  display: flex;
  align-items: center;
}

.items__container input::placeholder {
  color: #b1b1b1;
}

.items__container__field-btn {
  height: 100%;
  border: none;
  background-color: #046c77 !important;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
}

.items__previews {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.items__previews__item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.items__previews__item-path {
  flex: 1;
  color: #046c77;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.input-file__container__previews__item-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 2rem;
}

.input-file-error {
  padding: 0 !important;
  margin: 0 !important;
}
