.main {
  background: #04656F;
  background-size: cover;
  padding: 100px 0;
  /* height: 90vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding-bottom: 200px;
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.main__content {
  text-align: center;
  width: 65%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main__content-title {
  font-size: 1.7rem;
  font-weight: 500;
}

.main__content-desc {

}

.main__filter {
  display: flex;
  background-color: #FFFFFF;
  border-radius: 6px;
  height: 55px;
  color: #777777;
  padding: 0 0.5rem;
  gap: 0.5rem;
  width: 80%;
  margin: 0 auto;
}

.main__filter__search {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;

}
.main__filter__search-field {
  width: 100%;
  height: 100%;
}
.main__filter__selection {
  width: 50%;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main__filter__selection-field {
  width: 50%;
  position: relative;
}


.main__filter__selection-field:not(:last-of-type)::after {
  content: "";
  background-color: #EBEBEB;
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.main__filter__search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__filter__search-icon-btn {
  background-color: #24b3b9;
  padding: 0.7rem;
  border-radius: 6px;
}


@media only screen and (max-width: 992px) {
  .main__filter,
  .main__content  {
    width: 100%;
  }
}