.css-bfmp3d-MuiTypography-root {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
.css-ipdzgy-MuiButtonBase-root-MuiButton-root {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}

.css-12xuqdq {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
.css-15plk9t {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
.css-1c4qxl6 textarea {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
.css-1x5jdmq {
  font-family: "dinNextRegular" !important;
  font-weight: 500 !important;
}
