.logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 2px 2px 10px #ddd;
}

@media (max-width: 768px) {
  .logo {
    width: 70px;
    height: 70px;
  }
}
