.modal_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal_header_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal_header_row h2 {
  text-align: center;
  letter-spacing: 0;
  color: #046c77;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
}

.video_iframe {
  height: 350px;
}

.modal_close_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal_close_icon img {
  width: 100%;
  height: 100%;
}

.modal_header_divider {
  width: 100%;
  margin: 1rem 0 !important;
}

.upload_video_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  width: 100%;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #ebebeb;
  border-radius: 6px;
  opacity: 1;
  margin-bottom: 25px;
}

.modal_upload_icon {
  width: 52px;
  height: 37px;
}

.modal_youtube_icon {
  width: 143px;
  height: 100px;
}

.modal_upload_restriction_msg {
  text-align: right;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
}

.preview_video_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 540px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  opacity: 1;
}

.button_width {
  width: 249px;
  height: 75px;
}

.done_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 36px;
}

.modalContainer {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 16px;
  overflow-y: auto;
  max-height: 100vh;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .modalContainer {
    width: 90%;
  }
}

.alertOctagon {
  margin-bottom: 38px;
  width: 80px;
  height: 80px;
}

.confirmPaymentTxt {
  text-align: center;
  font-size: 24px;
  color: #555555;
  font-weight: 400;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
}

.yesBtn {
  background-color: #24b3b9;
  color: #ffffff;
  border-radius: 6px;
  border-color: #ebebeb;
  width: 150px;
  height: 50px;
}

.noBtn {
  background-color: #cfcfcf;
  color: #858585;
  border-radius: 6px;
  border-color: #ebebeb;
  width: 150px;
  height: 50px;
}

.btnBack {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.customButton {
  width: 249px;
  height: 60px;
  background: #046c77;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadBtn {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 26px !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #ffffff !important;
}

.uploadBtn:hover {
  background-color: #ffffff !important;
}
