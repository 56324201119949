.confirmation-modal {
  background-color: rgb(33, 33, 33, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal__container {
  background-color: #ffffff;
  width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
}

.confirmation-modal__close {
  position: absolute;
  right: 1.5rem;
  left: auto;
  top: 1.5rem;
  cursor: pointer;
}

body[dir="rtl"] .confirmation-modal__close {
  left: 1.5rem;
  right: auto;
}

.confirmation-modal__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.confirmation-modal__actions-btn {
  background-color: #046c77;
  color: #ffffff;
  border-radius: 6px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 2rem;
}

.confirmation-modal__actions-btn-reject {
  background-color: transparent;
  border: 1px solid #ef2929;
  color: #ef2929;
}
