.courses_card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.admin_coupon_radio {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  width: 40%;
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

.courses_card_courses {
  width: 100%;
  height: 300px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
}

.courses_card_first_courses {
  background-image: url("../../../../assets/image/lapcoat.png");
  background-color: #ccc;
  object-fit: cover;
  background-position: top;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}

.courses_name_course {
  width: 100%;
  background-color: rgba(3, 41, 43, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  transition: all 0.5s;
  padding-bottom: 20px;
  padding: 20px 10px;
  text-align: initial;
}

.courses_name_course:hover {
  height: 100%;
  /* background-image: url("../../image/logo.png"); */
  background-size: 100px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  float: right !important;
}

.courses_name_course:hover > .create_couses_edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courses_name_course:hover > .create_courses_name > .create_couses_edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.create_courses_name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.courses_name_course:hover > .create_courses_name > .create_couses_edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.create_couses_edit {
  display: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.courses_name_course:hover > .create_couses_edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courses_name_course:hover > .create_courses_name > .create_couses_edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.logo_hover_width {
  width: 100px;
}

.menu_icon {
  margin: 0 5px;
  width: 16px;
}
