html {
  height: 100%;
}
body {
  font-family: dinNextRegular, sans-serif !important;
  background-color: #f7f7f7 !important;
}
body::-webkit-scrollbar {
  width: 10px;
}

*:not(i) {
  font-family: dinNextRegular, sans-serif;
}

body::-webkit-scrollbar-track-piece {
  background: rgba(3, 22, 23, 0.85);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #006d77;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(3, 22, 23, 0.85);
}

.inner-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(212, 212, 212, 0.85);
  border-radius: 2rem;
}

.inner-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.inner-scrollbar::-webkit-scrollbar-track-piece {
  background: #ffffff;
  border: 1px solid rgba(222, 222, 222, 0.85);
  border-radius: 2rem;
}
.inner-scrollbar_blue::-webkit-scrollbar-thumb {
  background: #006d77;
  border-radius: 2rem;
}

.inner-scrollbar_blue::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.inner-scrollbar_blue::-webkit-scrollbar-track-piece {
  background: #ffffff;
  border: 1px solid rgba(222, 222, 222, 0.85);
  border-radius: 2rem;
}

body::-webkit-scrollbar-thumb {
  background: #006d77;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: initial;
  background-color: #f7f7f7;
  height: 100%;
  overflow-x: hidden !important;
}

.table td {
  vertical-align: middle;
}

.pagination {
  justify-content: center;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #006d77;
  border-color: #006d77;
}

.page-link {
  color: #006d77;
}

.page-link:focus {
  box-shadow: none;
}

.tajah-basic-modal-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tajah-basic-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.tajah-basic-modal-body {
  margin-top: 10px;
}

.tajah-basic-modal-md {
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  height: 500px;
  overflow-y: auto;
}

.tajah-basic-modal-mui {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  max-height: 80%;
  overflow-y: scroll;
}

.tajah-modal-dismiss {
  color: red;
  cursor: pointer;
}

#root {
  position: relative;
  height: 100%;
}

#root > div:first-of-type {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-box {
  display: flex;
  width: 100%;
}

button,
select,
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

input {
  outline: none;
}

input::-webkit-input-placeholder {
  opacity: 0.5; /* Edge */
}

input:-ms-input-placeholder {
  opacity: 0.5; /* Internet Explorer 10-11 */
}

input::placeholder {
  opacity: 0.5;
}

button {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
  transition: height 0.5s;
}

.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:checked {
  background-color: transparent !important;
}

.min-hight-78 {
  min-height: calc(78vh - 20px);
}

.min-hight-78-200 {
  min-height: calc(78vh - 189px);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: none;
}

a:hover {
  text-decoration: none;
}

.no-record {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

/* skeleton */
.skeleton-circle {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #acacac;
  border-radius: 50%;
  height: 30px;
}

.skeleton-box {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.skeleton-flex-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.skeleton-box::after,
.skeleton-circle::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #e2e2e2 30%,
    #eff1f3 60%,
    #e2e2e2 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

.custom_select {
  position: relative;
  display: inline-block;
  width: 130px;
}
.custom_select select {
  height: 30px;
  border-color: #dcdcdc;
  background: #dcdcdc;
  padding-right: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  border-radius: 5px;
}

.custom_select::after {
  content: "";
  position: absolute;
  right: 5px;
  top: 11px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 1);
  pointer-events: none;
}

body[dir="rtl"] .custom_select::after {
  content: "";
  position: absolute;
  left: 5px;
  right: unset;
  top: 11px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 1);
  pointer-events: none;
}

.spinner_container {
  background-color: rgba(132, 132, 132, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  /* height: 350px !important; */
  /* height: 120px !important; */
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}

table {
  width: 100%;
}

@keyframes shimmer {
  100% {
    transform: translate(100%);
  }
}

.skeleton-margin-top-bottom {
  margin: 25px 0;
}

.skeleton-margin-bottom {
  margin-bottom: 25px;
}

.skeleton-margin-left-right {
  margin: 0 25px;
}

.Toastify__progress-bar--default {
  background: none !important;
  display: none;
}

.Toastify__toast--default {
  background: #dc3545 !important;
  color: #f8f9fa !important;
}

#tt1q4oh2h,
#z3qqleq5r {
  display: none;
}

.Toastify__toast .Toastify__toast--default {
  display: none;
}

.Toastify__toast .Toastify__toast--default:first-child {
  display: none;
}

.Toastify__toast-body {
  font-size: 18px;
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

button:focus {
  outline: none !important;
}

.card-body {
  padding: 0;
}

.link-style {
  color: #fff;
  text-decoration: none;
}

ul {
  padding: 0 20px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #006d77;
  background: -moz-linear-gradient(left, #006d77 10%, rgba(69, 69, 69, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #006d77 10%,
    rgba(69, 69, 69, 0) 42%
  );
  background: -o-linear-gradient(left, #006d77 10%, rgba(69, 69, 69, 0) 42%);
  background: -ms-linear-gradient(left, #006d77 10%, rgba(69, 69, 69, 0) 42%);
  background: linear-gradient(to right, #006d77 10%, rgba(69, 69, 69, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #006d77;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 !important;
  }
  .carousel-inner .carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    justify-content: center;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }

  .tajah-basic-modal-md,
  .tajah-basic-modal-mui {
    width: 90%;
  }

  .tajah-basic-modal-mui {
    padding: 20px;
  }
}
table thead,
.table.table-bordered thead th {
  background: rgb(0, 109, 119) !important;
  color: white !important;
  border: 0px !important;
  vertical-align: middle !important;
  font-weight: 400 !important;
}

.surveys {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  body[dir="rtl"] .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  body[dir="rtl"] .offset-xl-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }
  body[dir="rtl"] .offset-xl-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }
}

body[dir="rtl"] .main-pagination svg {
  transform: rotate(180deg);
}

.text2lines {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.sun-editor .se-toolbar {
  z-index: auto !important;
}
.sun-editor .se-wrapper {
  z-index: auto !important;
}
