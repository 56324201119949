
.box-width {
    width: 90%;
}

@media (max-width:991px) {
    .box-width {
        width: 100%;
    }
}
