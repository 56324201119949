.warning-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin: 30px;
}

.warning-img {
  width: 100px;
}

.warning-img img {
  width: 100%;
}

.label {
  text-align: center;
  color: #066d77;
  margin-bottom: 10px;
}

.description {
  color: #a7a7a7;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 991px) {
  .modal-w {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .modal-w {
    width: 300px;
  }
}

.button-width {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}
