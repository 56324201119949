.training_departments_list .slick-track,
.training_departments_list--less-than-4 .slick-track {
  display: flex;
  margin: 0;
}

body[dir="rtl"] .training_departments_list--less-than-4 .slick-track {
  flex-direction: row-reverse;
}

body[dir="rtl"] .training_departments_list .slick-slide,
body[dir="rtl"] .training_departments_list--less-than-4 .slick-slide {
  margin: 0 20px 0 0;
}

body[dir="ltr"] .training_departments_list .slick-slide,
body[dir="ltr"] .training_departments_list--less-than-4 .slick-slide {
  margin: 0 0 0 20px;
}

.training_departments_list .slick-slide:first-child,
body[dir="rtl"] .training_departments_list--less-than-4 .slick-slide:last-child,
body[dir="ltr"] .training_departments_list--less-than-4 .slick-slide:first-child {
  margin: 0;
}
