.activities-list {
  padding: 2rem 0;
}

.activities-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.replays_container {
  display: flex;
  width: 100%;
  border-top: 0.5px solid rgb(229 231 235 / var(--tw-border-opacity));
  padding-top: 10px;
}
.replays_container_disable {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 0.5px solid rgb(229 231 235 / var(--tw-border-opacity));
  padding-top: 10px;
}
.replays_button {
  display: flex;
  width: 50%;
  justify-content: center;
  color: #24b3b9;
}
.Status {
  display: flex;
}

@media (max-width: 767px) {
  .comment_buttons {
    flex-direction: column;
    align-items: center;
    /* justify-content: center;
    height: 100%; */
  }
  .Status {
    flex-direction: column;
    align-items: center;
  }
}
.button_split_div {
  width: 1px;
  background-color: #c9c8c8;
}
.button_img {
  width: 25px;
  height: 25px;
}

.activities-list__back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.activities-list__back-button span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #7c7c7c;
}

.comment_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}
.action_button_comment {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-top: 20px;
}
.comment_buttons {
  display: flex;
  gap: 5px;
}

.booth-status-box {
  padding: 0 20px 5px;
  border-radius: 20px;
  font-size: 12px;
}

.booth-status-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.booth-status-box-banned {
  background-color: rgba(233, 75, 47, 0.767);
  color: #ffffff;
}
.booth-status-box-waiting {
  background-color: rgb(168, 167, 167);
  color: #ffffff;
}
.booth-status-box-published {
  background-color: rgba(37, 138, 37, 0.74);
  color: #ffffff;
}
