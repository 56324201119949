.payment-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.course_payment_container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  min-height: 1080px;
}

.course_payment_notes_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.course_payment_note {
  padding: 0.5rem 1rem;
  background-color: #e0edd4;
  color: #707070;
  font-size: 1rem;
}

.course_payment_note:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.course_payment_note:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.course_payment_form_container {
  width: 100%;
}

.course_payment_details_container {
  width: 100%;
  margin-top: 1.4rem;
}

.course_payment_form_separator {
  width: 2px;
  background-color: #ebebeb;
}

.payment-back {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.payment-back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.payment-back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body[dir="ltr"] .payment-back-icon img {
  transform: scale(-1);
}
.payment-back-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  padding-bottom: 0.4rem;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.payment-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.payment-box-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.payment-box-options {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.payment-box-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 250px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
}

.payment-box-option-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 250px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
  background-color: #24b3b9;
  color: #fff;
}

.payment-box-option img {
  width: 75px;
}

.payment-box-option-selected img {
  filter: brightness(0) invert(1);
}

.payment-box-option h4 {
  color: #b1b1b1;
  font-size: 18px;
  font-weight: 400;
}

.payment-fields {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.payment-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-details-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.payment-details-price-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ebebeb;
  border-radius: 6px;
}

.payment-details-price-item,
.payment-details-price-item-plus,
.payment-details-price-item-minus {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-details-price-item:not(:last-child),
.payment-details-price-item-plus:not(:last-child),
.payment-details-price-item-minus:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.payment-details-price {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-details-price-title,
.payment-details-price-percentage {
  font-size: 1rem;
  font-weight: 600;
  color: #707070;
}

.payment-details-price-value {
  font-size: 1rem;
  font-weight: 600;
  color: #707070;
}

.payment-details-price-item-plus .payment-details-price-value {
  color: #df4b43;
}

.payment-details-price-item-minus .payment-details-price-value {
  color: #24b3b9;
}

.payment-details-total-price-item {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
}

.payment-details-total-price-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #707070;
}

@media screen and (max-width: 450px) {
  .payment-details-total-price-title-span {
    display: block;
  }
}

.payment-details-total-price-value {
  font-size: 1.4rem;
  font-weight: 600;
  color: #707070;
}

.payment-details-coupon-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.payment-details-coupon-input {
  width: 100%;
}

.payment-details-coupon-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  margin-top: 1.4rem;
}

.payment-details-coupon-button-disabled {
  background-color: #ebebeb !important;
}

.payment-details-pay-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-top: 2rem;
}

.payment-info-container-opened {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
}

.payment-info-container span,
.payment-info-container-opened span {
  font-size: 1.2rem;
  font-weight: 600;
}

.payment-info-container img,
.payment-info-container-opened img {
  width: 2rem;
  height: 2rem;
}

.payment-info-item-box {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.payment-info-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.payment-info-item-title {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}

.payment-info-item-value {
  font-size: 1rem;
  font-weight: 600;
  color: #24b3b9;
}

.payment-box-option-amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .course_payment_container {
    flex-direction: column;
    gap: 1rem;
  }

  .course_payment_notes_container {
    width: 100%;
  }

  .course_payment_form_container {
    width: 100%;
  }

  .payment-details {
    width: 100%;
  }
}
.traniee_msg {
  font-size: 12px;
  color: #707070;
  margin-bottom: 5px;
}
.select-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}
