.chapter-row-container-opened,
.sub-chapter-row-container-opened {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.chapter-row-container,
.chapter-row-container-opened {
  margin: 1rem 0;
}

.sub-chapter-row-container,
.sub-chapter-row-container-opened {
  margin: 1rem 2rem;
}

.lesson-row-container {
  margin: 1rem 2rem;
  display: none;
}

.lesson-row-container-opened {
  display: block;
}

.add-new-item-container {
  margin: 0 2rem;
}

.course-details-form {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  padding: 1rem 2rem;
  margin: 0 2rem;
}

.course-details-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.training-course-syllabus-section-container-border {
  background: white;
  width: 100%;
  height: 100%;
  box-shadow: 2px 3px 6px #cfcfcf;
  border-radius: 8px;
  transition: opacity 0.5s;
}

.auth_btn {
  width: 100%;
  border-radius: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #515f60;
}

.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.personal_label {
  display: flex;
}

.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
}

.training-course-syllabus-section-container-row-lesson:last-child,
.training-course-syllabus-section-container-row-lesson-header.closed:first-child {
  border-radius: 13px;
  border-bottom-width: 0px;
}

.training-course-syllabus-section-container-row-lesson-header {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  border-bottom-color: #cfcfcf;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  cursor: pointer;
}

.training-course-syllabus-section-container-row-lesson-content.closed {
  height: 0px;
  opacity: 0;
}

.form-fields {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .personal_label {
    font-size: 10px;
  }
}
.training-course-syllabus-section-actions {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .personal_label {
    font-size: 12px;
  }

  .training-course-syllabus-section-container-row-lesson-header {
    flex-wrap: wrap;
    gap: 5px;
  }
  .training-course-syllabus-section-container-row-lesson-header > div:first-of-type {
    width: 100%;
  }

  .training-course-syllabus-section-actions {
    margin-top: 20px;
    padding: 0;
  }
  .training-course-syllabus-section-actions button,
  .training-course-syllabus-new-chapter-action button {
    width: 100% !important;
  }
}
