.main-table {
  overflow-x: auto;
}

.sort-icon {
  margin: 0 0.5rem;
  display: inline;
}

.main-table thead th {
  cursor: pointer;
}

.main-table table thead,
.main-table thead th {
  background: #046c77 !important;
  color: #ffffff;
  font-weight: 400;
  border: none;
  text-align: center;
}

.main-table {
  min-height: auto;
  margin: 0 4px;
}

.main-table tbody td {
  text-align: center;
  white-space: nowrap;
  color: #777777;
}

.main-table td,
.main-table th {
  border: 1px solid #ebebeb;
}

.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-data-container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.main-table__column--start {
  text-align: start !important;
}

.photo_inside_table {
  width: 70px;
  border-radius: 50%;
  height: 70px;
  object-fit: cover;
}
