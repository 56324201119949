.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-border-radius: 50%;
}
@media (max-width: 767px) {
  .admin_img {
    width: 30px;
    height: 30px;
  }
}
