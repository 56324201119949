.admin_label {
  display: flex;
  margin: 30px 0;
}

.table_header.titleAddFile {
  color: #046c77;
  font-size: 34px;
  margin-bottom: 0;
}

.main_box {
  padding: 30px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 50px;
}

.main_inside_box {
  width: 100%;
}

.main_label {
  margin-bottom: 40px;
}

.custom_main_width {
  width: 100%;
}

.main_box_label {
  font-size: 14px;
  margin: 0px 0 10px;
}

.admin_add_input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50px;
}

.main_box_input_add {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  padding: 12px 18px;
  color: #858585;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.admin_ipblock_radio {
  display: flex;
  font-size: 14px;
  gap: 4rem;
  padding: 0 1.2rem;
}

.form-check-label {
  padding: 0 20px;
}

.admin_add_input {
  height: 50px;
}

.slash-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4%;
}

.personal_check {
  padding: 0 0px;
}

.personal_radio .form-check {
  padding: 0;
}

.form-check {
  padding: 1.2rem;
}

.checkbox > input {
  height: 15px;
  width: 15px;
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #006d77;
  background-color: #006d77;
}

.main_box_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77 !important;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.main_box_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.form-check-label-ipblock {
  padding: 0px;
}

.gender_name {
  margin-right: 10px;
  margin-left: 10px;
}

.shape_radio_ipblock {
  display: none;
}

.shape_radio_ipblock + label {
  /* -webkit-appearance: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e1e1e1; */
  border: 1px solid #7e7e7e;
  border-radius: 50px;
  width: 100%;
  /* display: inline-block; */
  /* position: relative; */
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio_ipblock + label span {
  /* -webkit-appearance: none; */
  /* background-color: #e1e1e1; */
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.form-check-input {
  width: fit-content;
  margin-right: -1.25rem !important;
}

.shape_radio_ipblock:checked + label span {
  background: #006d77;
  border: 1px solid #7e7e7e;
}

.input_radio_container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 991px) {
  .admin_label {
    font-size: 16px !important;
  }

  .custom_main_width {
    width: 100% !important;
  }

  .form-check-label {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .admin_label {
    font-size: 14px !important;
  }

  .personal_check {
    padding: 0 15px;
  }

  .field-group {
    flex-direction: column;
  }

  .field-item {
    width: 100% !important;
  }
}

.py-30 {
  padding: 30px 0 !important;
}
