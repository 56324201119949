.exhibition_list {
  padding: 2rem 0;
}

.exhibition_list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.exhibition_list__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 150px;
}

.exhibition_list__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.exhibition_list__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.exhibition-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}
