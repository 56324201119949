.breadcrumbs-my-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.body-faq {
	background-color: white;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	padding: 30px;
	border-radius: 8px;
	margin: 0px auto 70px;
}

.header-faq {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 30px;
	border-bottom: 2px solid #d4cccc;
}

.header-faq h3 {
	color: #2e6a75;
	margin-bottom: 0;
	font-weight: bold;
}

.header-faq-icon {
	background-color: #2e6a75;
	width: 35px;
	border-radius: 4px;
	padding: 10px;
	cursor: pointer;
}

.header-faq-q {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 40px;
	padding-bottom: 25px;
}

.header-faq-q h5 {
	color: #2e6a75;
	font-size: 22px;
	font-weight: bold;
}

.header-faq-q .icons {
	display: flex;
	align-items: center;
}

.header-faq-q-title {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.delete-icon {
	color: #e62626;
	width: 30px !important;
	height: 30px !important;
	padding: 2px;
	border: 1px solid #e62626;
	border-radius: 4px;
	cursor: pointer;
}

.edit-icon {
	color: #d1d626;
	padding: 4px;
	width: 30px !important;
	height: 30px !important;
	border: 1px solid #d1d626;
	border-radius: 6px;
	cursor: pointer;
	border-radius: 4px;
	margin-inline: 8px;
	margin-inline-end: 8px;
	cursor: "pointer";
}

.add-question {
	margin-bottom: 40px;
}

.mb-4 {
	margin-bottom: 4px;
}

.faq-item {
	border: 0;
	margin-bottom: 50px;
	transition: max-height 0.6s ease-in-out;
	/* max-height: 70px; */
}

.faq-item.active_faq {
	max-height: 100%;
	border: 1px solid #ddd;
}

.faq-question {
	padding: 20px 20px;
	cursor: pointer;
	background-color: #fff;
	border-radius: 6px;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.faq-item.active_faq .faq-question {
	background-color: #56b1b7;
	color: #fff;
	border: 0;
}

.faq-question p {
	margin-bottom: 0.5rem;
	font-size: 18px;
}

.dashes-line {
	width: 43%;
	border-bottom: 1px dashed #d0d0d0 !important;
	margin: 10px 0;
}

.faq-container {
	background-color: white;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	padding: 20px 20px 20px;
	border-radius: 8px;
	margin: 0px auto 15px;
}

.notfound {
	font-size: 30px;
	color: #2e6a75;
	font-weight: bold;
}

@media (max-width: 1023px) {
	.header-faq h3 {
		font-size: 24px;
	}

	.header-faq-icon {
		width: 25px;
		padding: 5px;
	}

	.header-faq-q h5 {
		color: #2e6a75;
		font-size: 18px;
		font-weight: bold;
	}

	.delete-icon {
		width: 25px !important;
		height: 25px !important;
	}

	.edit-icon {
		width: 25px !important;
		height: 25px !important;
	}

	.dashes-line {
		width: 35% !important;
	}

	.notfound {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	.header-faq h3 {
		font-size: 18px;
	}

	.dashes-line {
		width: 25% !important;
	}

	.header-faq-q h5 {
		color: #2e6a75;
		font-size: 14px;
		font-weight: bold;
	}

	.header-faq-q {
		flex-direction: column;
		gap: 10px;
	}
}
