.departments-trainees {
  padding: 2rem 0;
}

.departments-trainees__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departments-trainees__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departments-trainees__table-name,
.departments-trainees__table-desc {
  width: 300px;
  white-space: pre-wrap;
}

.departments-trainees__table-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.note_table {
  white-space: pre-line;
}

.departments-trainees__table-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.departments-trainees__table-certificate,
.departments-trainees__table-subscription {
  border-radius: 6px;
  height: 30px;
  padding: 0 15px;
}

.departments-trainees__table-certificate-no {
  background-color: rgb(232, 145, 145, 0.6);
  color: #e22020;
}

.departments-trainees__table-certificate-yes {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}

.departments-trainees__table-subscription-yes {
  background-color: rgb(193, 226, 200, 0.6);
  color: #46bb3e;
}
.departments-trainees__table-subscription-no {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}

.departments-trainees__table-payment-method {
}

.departments-trainees__table-payment-method--banktransfer {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
.departments-trainees__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.departments-trainees__table-email:hover {
  color: #24b3b9 !important;
}

.reject-payment-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.reject-payment-modal__header h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.reject-payment-modal__header img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.reject-payment-modal__body {
  padding: 1.5rem;
}

.reject-payment-modal__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reject-payment-modal__submit-btn {
  padding: 1rem 6rem;
}

.payment-method--bank {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.bank-modal {
  background-color: rgb(33, 33, 33, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-modal__container {
  background-color: #ffffff;
  width: 45rem;
  height: 45rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
}

.bank-modal__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bank-modal-title {
  color: #046c77;
  font-size: 30px;
  font-weight: 500;
}

.bank-modal-value {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bank-modal-value-label {
  font-size: 18px;
  color: #046c77;
}
.bank-modal-value-box {
  background-color: #046c77;
  color: #ffffff;
  padding: 0 1rem;
  height: 50px;
  font-size: 30px;
  border-radius: 6px;
}

.bank-modal-value-box sub {
  font-size: 20px;
}

.bank-modal__close {
  position: absolute;
  right: 1.5rem;
  left: auto;
  top: 1.5rem;
  cursor: pointer;
}

body[dir="rtl"] .bank-modal__close {
  left: 1.5rem;
  right: auto;
}

.bank-modal__field {
  margin-bottom: 1rem;
}
.bank-modal__image {
  position: relative;
  height: fit-content;
  border-radius: 6px;
}
.bank-modal__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bank-modal__image-download {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 25px;
  background: #e9ecef;
  height: 25px;
  border-radius: 3px;
}

.bank-modal__content {
  max-height: 65vh;
  overflow-y: auto;
}

/**** custom scrollbar ****/
.bank-modal__content::-webkit-scrollbar {
  width: 8px;
}
.bank-modal__content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.bank-modal__content::-webkit-scrollbar-thumb {
  background-color: #046c77;
  border-radius: 4px;
}
.bank-modal__content::-webkit-scrollbar-thumb:hover {
  background-color: #046c77;
}

.input-file__container__previews__item {
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.3rem;
  width: fit-content;
}

.input-file__container__previews__item-document {
  background-color: rgb(223 223 223 / 20%);
}

.input-file__container__previews__item-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-file__container__previews__item-img img {
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}

.price-plus {
  color: #24b3b9;
  padding: 0 0.5rem;
  border-radius: 9999px;
  background-color: #24b3b922;
}

.price-minus {
  color: #ff0000;
  padding: 0 0.5rem;
  border-radius: 9999px;
  background-color: #ff000022;
}

@media only screen and (max-width: 768px) {
  .bank-modal__container {
    width: 90%;
  }
}

@media only screen and (max-width: 580px) {
  .bank-modal-title,
  .bank-modal-value-box {
    font-size: 20px;
  }
  .bank-modal-value-box sub {
    font-size: 16px;
  }
  .bank-modal-value {
    justify-content: space-between;
  }
  .bank-modal-value-box {
    height: 40px;
  }
}
