.result_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title_font_size {
  font-size: 24px;
  line-height: 32px;
}
.question_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem;
}
.mark_font_size {
  font-size: 3rem /* 48px */;
  line-height: 1;
}
.pass_box {
  border-radius: 9999px;
}
.time_size {
  font-size: 1rem;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  padding-left: 2rem /* 32px */;
  padding-right: 2rem /* 32px */;
}

.back {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body[dir="ltr"] .back-icon img {
  transform: scale(-1);
}

.back-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  padding-bottom: 0.4rem;
}

@media screen and (max-width: 992px) {
  .result_container {
    flex-direction: column;
  }
  .question_title_container {
    flex-direction: column;
    font-size: 15px;
    line-height: "";
    align-items: flex-start;
    gap: 5px;
  }
  .title_font_size {
    font-size: 16px;
    line-height: "";
    font-weight: bold;
  }
  .mark_font_size {
    font-size: 2rem;
    line-height: "";
  }
  .pass_box {
    border-radius: 0.375rem;
  }
  .time_size {
    font-size: 0.75rem;
    padding: 1rem;
  }
}
