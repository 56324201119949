.file-preview {
  border-radius: 6px;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  height: 70px;
}

.file-preview-img {
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.file-preview-name {
  flex: 1;
  color: #444444;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.file-preview-download {
  background: none;
  border: none;
  padding: 0;
}
