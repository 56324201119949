.options {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}
.options__item {
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.options__item--disabled {
  cursor: not-allowed;
}

.options__item:hover {
  background-color: #8599b6;
}
.options__item--disabled:hover {
  background-color: transparent;
}
.options__item svg {
  width: 1.5em;
}
.options__item path,
.options__item ellipse,
.options__item line {
  transition: all 0.2s ease-in-out;
}

.options__item:hover path,
.options__item:hover ellipse,
.options__item:hover line {
  stroke: #ffffff;
}

.options__item--disabled path,
.options__item--disabled ellipse,
.options__item--disabled line {
  stroke: #c9c9c9;
}

.options__item--disabled:hover path,
.options__item--disabled:hover ellipse,
.options__item--disabled:hover line {
  stroke: #c9c9c9;
}
