.carousel-title {
  font-size: 18px;
  padding-bottom: 15px;
}

.MuiRating-iconEmpty.css-1c99szj-MuiRating-icon {
  color: #b5b5b5 !important;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  transform: rotate(90deg);
}

.css-wjh20t-MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
}
