.card {
  background-color: #fff;
  /* border-radius: 6px; */
  width: 100%;
  gap: 2rem;
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 10px #ddd;
}

.header-content,
.bottom-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.img-container {
  position: relative;
  width: 100%;
  height: 250px;
  /* border-radius: 6px; */
  border-bottom: 10px solid #4e8284;
  background-size: cover;
  background-position: center center;
}

.img-badge {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.offer-badge {
  position: absolute;
  top: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 10px;
  background-color: #fff;
  color: #ea5e2d;
  border-radius: 20px;
}
body[dir="rtl"] .offer-badge {
  left: 18px;
}
body[dir="ltr"] .offer-badge {
  right: 18px;
}

.offer-badge-number {
  font-size: 12px;
  font-weight: bold;
}

.offer-badge-off {
  font-size: 1.2rem;
  font-weight: bold;
}

.img {
  width: 100%;
  height: 100%;
  /* border-radius: 6px; */
}

.title-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.desc-container {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* desc-container and all its children */
.desc-container,
.desc-container > *,
.desc-container > * > * {
  color: #acabab !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  padding: 6px 15px 0px;
  border-radius: 20px;
  max-width: 100%;
}

.price-number {
  line-height: 0.8;
}

.old-price {
  color: #777777;
  font-size: 12px;
  text-decoration: line-through;
}

.box-w {
  max-width: 70%;
}

.price-label {
  color: #046c77;
  font-size: 12px;
  text-align: center;
}

.price {
  color: #24b3b9;
  font-weight: bold;
}

.cur {
  font-size: 8px !important;
}

.price span {
  font-size: 20px;
}

.date-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-seat-hour {
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
}

.date-seat-hour span {
  color: #24b3b9;
}

.btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-style {
  border-radius: 6px !important;
  padding-top: 5px;
  padding-bottom: 8px;
  height: fit-content;
}

.badge-font {
  font-size: 12px;
}

.star-font-size::before {
  font-size: 1rem !important;
}

.flip-star {
  transform: rotateY(180deg);
}

.user-container {
  display: flex;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -10px;
  border: 1px solid #e4e2e2;
}

.user-img-more {
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #777777;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-black {
  color: #8599b6 !important;
  background-color: #8599b638 !important;
}

.badge-yellow {
  color: #c28d51 !important;
  background-color: #c28d5138 !important;
}

.badge-green {
  color: #24b951 !important;
  background-color: #24b95138 !important;
}

.iconscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #6d6d6d;
}

.progress-container {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.progress-container h3 {
  font-size: 0.8rem;
  font-weight: 600;
  color: #555;
  min-width: fit-content;
  width: 80px;
}

.progress-container p {
  font-size: 14px;
  font-weight: 500;
  color: #555;
  min-width: fit-content;
  width: 50px;
}

.progress {
  display: flex;
  width: 100%;
  /* margin: 10px auto; */
  height: 5px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #e4e2e2;
}

.progress-bar {
  border-radius: 6px;
  background-color: #18b1b9;
}

.watch-progress-bar {
  border-radius: 6px;
  background-color: #ff9300;
}

@media (max-width: 767px) {
  .card {
    /* max-width: 380px;
    width: 340px; */
  }

  .date-badge {
    flex-direction: column;
    gap: 20px;
  }
}
.num_courses {
  display: flex;
  gap: 15px;
  align-items: center;
  /* background-color: #75afb32f; */
  /* padding: 10px 25px; */
  /* border-radius: 20px; */
  /* max-width: 200px; */
  /* justify-content: center; */
  color: #000;
  font-weight: 900;
  /* margin: 0 auto; */
  margin-top: 5px;
  font-size: 14px;
}
.numContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #488b85;
  font-weight: bold;
  white-space: nowrap;
  font-size: 10px;
}
.sapretor {
  height: 3px;
  margin: 5px 0;
  margin-top: 10px;
  background: linear-gradient(
    270deg,
    rgba(138, 140, 140, 0.4767156862745098) 26%,
    rgba(233, 229, 229, 0.514) 68%
  );
}
body[dir="ltr"] .sapretor {
  background: linear-gradient(
    270deg,
    rgba(233, 229, 229, 0.514) 26%,
    rgba(138, 140, 140, 0.4767156862745098) 68%
  );
}

.job_title p {
  /* text-align: center; */
  font-size: 12px !important;
  color: #959595;
  font-weight: 600;
  margin-top: 10px;
  /* height: 50px; */
}
.cardContantNew {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
  gap: 5px;
}
.levelbadge {
  padding: 0 20px;
}
.progressMain {
  padding: 0 20px;
}
.iconClass {
  width: 28px !important;
  height: 24px !important;
  margin-top: 2px;
}

body[dir="rtl"] .iconClass {
  transform: rotateY(180deg);
}
.btnCont {
  width: 100%;
  padding: 0 20px;
}
.hovering {
  transform: scale(1.05);

  transition: 500ms ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
