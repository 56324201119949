.sso-popup {
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  z-index: 100001;
  width: 30%;
  min-height: 100px;
  right: calc((100% - 30%) / 2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px 30px;
}

.sso-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.sso-logo {
  width: 200px;
}

.sso-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sso-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sso-card  span{
    width: 100% !important
}

.sso-label {
  margin-top: 30px;
  font-size: 25px;
}

.sso-input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50px;
}

.sso-input-label {
  font-size: 14px;
  margin-top: 20px;
}

.sso-btn {
  padding: 10px;
  background-color: #006d77;
  color: #fff;
  font-size: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: none;
  outline: none;
  offset: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-bottom: 0;
  width: 50%;
}

@media (max-width: 1280px) {
  .sso-popup {
    width: 45%;
    right: calc((100% - 45%) / 2);
  }
}

@media (max-width: 991px) {
  .sso-popup {
    width: 65%;
    right: calc((100% - 65%) / 2);
  }

  .sso-btn {
    width: auto;
  }
}

@media (max-width: 768px) {
  .sso-popup {
    width: 90%;
    right: calc((100% - 90%) / 2);
  }
}
