.container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.admin_label {
  font-size: 18px;
  display: flex;
  margin: 30px 0;
}

.unity-booth-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  padding: 0 140px;
  margin: 30px 0;
}

.unity-booth-info-public {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
}

.unity-booth-rating {
  display: flex;
  width: 40%;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-top: 18px;
  padding: 0 4rem;
}

.unity-booth-rating-typography {
  color: #046c77;
  font-size: 20px;
  font-weight: 600;
}

.rateStyle {
  transform: rotate(180deg) !important;
}

.unity-rating-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.rating-now-bts {
  width: 75px;
  height: 40px;
  background-color: #046c77 !important;
  color: #fff;
  padding-bottom: 5px;
  border-radius: 6px;
  font-size: 14px;
}

.unity-booth-webinars-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  padding: 42px 94px;
}

.unity-booth-info-public-booth-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
}

.unity-booth-info-public-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.unity-booth-info-public-data-title {
  color: #046c77;
  font-size: 20px;
  font-weight: 600;
}

.unity-booth-info-public-data-numbers-date {
  font-size: 14px;
  font-weight: 400;
  color: #046c77;
}

.unity-booth-info-public-data-numbers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.unity-booth-info-webinar-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 50px; */
  border-radius: 6px;
  /* background: rgb(104, 183, 186); */
  /* background: linear-gradient(90deg, rgba(104, 183, 186, 1) 0%, rgba(36, 179, 185, 1) 100%); */
  /* padding: 10px 20px; */
}

.unity-booth-info-webinar-link-img {
  width: 24px;
  height: 24px;
}

.unity-booth-info-webinar-link {
  text-decoration: none;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.unity-booth-frame {
  width: 85%;
  border: 1px solid #046c77;
  margin: 0 auto;
}

.unity-booth-frame-mute {
  position: absolute;
  bottom: 2.75rem;
  right: 18rem;
  width: 38px;
  height: 38px;
  background-color: #1eaed3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.unity-booth-frame-mute img {
  width: 24px;
  height: 24px;
}

.unity-booth-survey {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 60px;
  padding: 0 140px;
}

.unity-booth-survey-for-guest {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.unity-booth-survey-btn-items-collection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unity-booth-survey-ballot-check {
  width: 22px;
  height: 25px;
  margin: 0px 12px;
}

.unity-booth-survey-txt {
  font-size: 16px;
  font-weight: 500;
}

.unity-booth-back-btn {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.unity-booth-rating-comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.unity-booth-rating-comment textarea {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  resize: none;
}

.unity-booth-rating-comment-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.unity-booth-rating-comment-btn {
  width: 100px;
  height: 40px;
  background-color: #046c77;
  color: #fff;
  padding-bottom: 5px;
  border-radius: 6px;
  font-size: 14px;
}

.unity-booth-move-phone-btn {
  display: none !important;
}

@media screen and (max-width: 968px) {
  .unity-booth-info {
    flex-direction: column;
    padding: 0;
  }

  .unity-booth-info-public {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  .unity-booth-rating {
    width: 100%;
    padding: 0 1rem;
  }

  .unity-booth-rating-typography {
    font-size: 16px;
  }

  .unity-booth-webinars-container {
    padding: 24px;
  }

  .unity-booth-info-public-booth-img {
    width: 100px;
    height: 100px;
  }

  .unity-booth-info-public-data {
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .unity-booth-info-public-data-title {
    font-size: 16px;
  }

  .unity-booth-info-public-data-numbers-date {
    font-size: 12px;
  }

  .unity-booth-info-public-data-numbers {
    gap: 5px;
  }

  .unity-booth-info-webinar-link-container {
    height: 40px;
    padding: 5px 10px;
  }

  .unity-booth-info-webinar-link {
    font-size: 12px;
  }

  .unity-booth-frame {
    width: 100%;
  }

  .unity-booth-survey {
    padding: 0;
    justify-content: center;
  }

  .unity-booth-survey-for-guest {
    padding: 0;
  }

  .unity-booth-survey-ballot-check {
    width: 15px;
    height: 20px;
    margin: 0px 12px;
  }

  .unity-booth-survey-txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .unity-booth-move-phone-btn {
    display: flex !important;
  }

  .unity-booth-frame-hidden,
  .unity-booth-move-btn-hidden {
    display: none !important;
  }

  .unity-booth-survey {
    justify-content: center !important;
    flex-wrap: wrap !important;
  }

  .unity-booth-back-btn,
  .unity-booth-survey-btn,
  .unity-booth-tickets-btn,
  .unity-booth-move-btn,
  .unity-booth-move-phone-btn {
    width: calc(50% - 1rem) !important;
  }
}
