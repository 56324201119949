.description {
  padding: 20px 0;
  background-color: #fff;
}

.description-content {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 100%;
  word-break: break-word;
  color: #919191;
  margin-top: 15px;
}

.btn {
  width: 100%;
  margin-top: 35px;
  justify-content: center;
}
