.adminNav {
  display: flex;
}

.navbar_logo_admin {
  display: flex;
  gap: 10px;
  align-items: center;
}
.hamburger-pointer {
  cursor: pointer;
  margin: 0 10px;
}
.flex_menu {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  width: 100%;
}
.image_logo_new {
  max-width: 375px;
  width: 375px;
}

@media (max-width: 930px) {
  .image_logo_new {
    max-width: 300px;
    width: 300px;
  }
}
@media (max-width: 821px) {
  .image_logo_new {
    max-width: 200px;
    width: 200px;
  }
}
@media (max-width: 520px) {
  .image_logo_new {
    display: none;
  }
}
.backdrop_admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
}
.header_shadow {
  background-color: #fff;
  box-shadow: 0 0 21px #aaa;
  height: 111px;
  justify-content: center;
  align-items: center;
  display: flex;
}
