.price-box {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  padding: 1rem;
}
.price-box-label {
  font-weight: bold;
}

.price-box-value {
  font-size: 40px;
  font-weight: bold;
}

.price-box-value sub {
  font-size: 16px;
  font-weight: 400;
}
