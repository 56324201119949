.auth_forms_login {
  background-color: rgb(255 255 255) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column !important;
  width: 50%;
  color: #000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  padding-top: 20px;
}

.auth_forms_login input {
  color: #000;
}

.error_login {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #942b35;
  height: 15px;
}

.auth_btn {
  width: 100%;
  /* border-radius: 20px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #006d77;
}

.auth-home-login {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: none;
}

.auth-home-login svg {
  width: 30px;
  height: 30px;
  margin-top: -75px;
  margin-bottom: 75px;
}

body[dir="rtl"] .auth-home-login {
  justify-content: flex-start;
}

.auth_form_form {
  padding: 20px 40px;
}

.forgotPasswordBtn {
  color: #24b3b9;
  font-size: 16;
  text-decoration: underline;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
}

@media (max-width: 991px) {
  .auth_forms_login {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .auth_forms_login {
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .auth_label {
    font-size: 20px;
    font-weight: bold;
    padding: 0 15px;
  }

  .auth_form_form {
    padding: 0 15px;
  }
}
@media (max-width: 480px) {
  .auth_forms_login {
    gap: 1rem;
  }
}
