.category-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  gap: 10px;
  width: 270px;
  height: 295px;
  padding: 2rem 1.5rem;
  padding-top: 3rem;
  margin-bottom: 60px;
  margin-top: 10px;
  /* padding-bottom: 10px; */
  /* border-radius: 1rem; */
  /* border: 1px solid #e0e0e0; */
  background: linear-gradient(
    360deg,
    rgb(255, 255, 255) 68%,
    rgba(36, 180, 185, 0.24) 95%
  );
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.card-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1rem; */
}
body[dir="rtl"] .card-top {
  justify-content: flex-end;
}

.card-img {
  background: #fff;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* padding: 10px; */
  border-radius: 8px;
}
.texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
}
.card-title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #025c5c;

  /* padding-bottom: 1rem; */
}
.card-description {
  display: flex;
}

.card-description p {
  font-size: 15px !important;

  font-weight: 400;
  color: #7a7a7a;
}
body[dir="rtl"] .card-description {
  justify-content: flex-end;
}
body[dir="rtl"] .card-description p {
  justify-content: flex-end;
  direction: rtl;
}
.hovering {
  transform: scale(1.05);

  transition: 500ms ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
