.exhibition_list {
	padding: 2rem 0;
}

.exhibition_list__container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.exhibition_list__actions {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.exhibition_list__actions-btn {
	background: none;
	border: none;
	padding: 0;
}

.tickets-options {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.tickets-options-title {
	color: #046c77;
}
