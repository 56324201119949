.exhibition-details__audios-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.exhibition-details__audios-item-title {
  font-size: 16px;
  color: #777777;
}

.course-slider-item-content-inner-body,
.course-slider-item-content-inner-body > *,
.course-slider-item-content-inner-body > * > * {
  font-size: 14px !important;

  /* padding: 15px; */
}
.downloadFile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.file-download {
  height: 100px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.downloadicon {
  height: 100px;
  width: 100px;
}
