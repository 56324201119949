/*{*/
/*    box-sizing: border-box ;*/
/*}*/

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form-popup {
  display: none;
  position: relative;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 0;
}

/* Add styles to the form container */
.form-container-chat {
  max-width: 420px;
  z-index: 100;
  padding: 6px;
  border-radius: 4px;
  background-color: #f6fffe;
  position: fixed;
  bottom: 100px;
  min-height: 320px;
}

/* Full-width textarea */
.form-container-chat textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
}

/* When the textarea gets focus, do something */
.form-container-chat textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container-chat .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container-chat .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container-chat .btn:hover,
.open-button:hover {
  opacity: 1;
}

.left,
.right {
  display: block;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  /*clear: both;*/
  word-break: break-word;
}

.left {
  float: left;
  margin-left: 20px;
  background-color: white;
  color: #707070;
}

.right {
  float: right;
  margin-right: 20px;
  background-color: #316d66;
  color: white;
}

.chat-circle {
  position: fixed;
  right: 20px;
  bottom: 35px;
  width: 70px;
  height: 70px;
  background: #006d77;
  color: blue;
  border: 5px solid white;
  box-shadow: 1px 1px 10px 1px white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

body[dir="ltr"] .chat-circle {
  left: 20px;
}

.chat-bubble-header {
  height: 40px;
  border-bottom: solid;
  border-width: 1px;
  width: 100%;
  height: 100%;
  border-color: #dbe5ed;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px;
  background-color: #e4fffc;
}

@media only screen and (max-width: 768px) {
  .form-container-chat {
    display: block;
    width: calc(100% - 30px);
    left: 15px;
  }
}
