.aboutButton {
  color: #024444 !important;
  border-radius: 12px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.aboutUsMainCont {
  display: flex;
  justify-content: center;
  /* margin: 2rem 0; */
  padding: 6rem 0;
  height: 80vh;
  background-color: #133535;
}
.aboutUsMain {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  width: 100%;
  max-width: 1280px;
}
.sapretordiv {
  width: 1px;
  background-color: rgb(255, 94, 0);
}
.countdiv {
  display: flex;
  gap: 60px;
  align-items: center;
  width: 40%;
  justify-content: center;

  color: #fff;
}
@media (max-width: 1200px) {
  .aboutUsMainCont {
    height: 100%;
  }
  .aboutUsMain {
    flex-direction: column;
    align-items: center;
  }
  .sapretordiv {
    height: 1px;
    width: 80%;
    background-color: rgb(255, 94, 0);
  }
  .aboutContent {
    align-items: center;
    text-align: center;
  }
  .countdiv {
    width: 100%;
  }
}
.aboutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: #fff;
  /* max-width: 475px; */
  width: 60%;
}
@media (max-width: 1300px) {
  .aboutContent {
    margin-inline-start: 40px;
  }
}
@media (max-width: 767px) {
  .aboutContent {
    margin-inline-start: 0px;
  }
}
.aboutContent h1 {
  font-size: 70px;
  font-weight: bold;
}
.aboutContent p {
  max-width: 475px;
}
.aboutContent h4 {
  font-size: 24px;
}
.tebx {
  color: rgb(255, 94, 0) !important;
}

.count {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.count h1 {
  font-size: 36px;
  font-weight: bold;
}

.counterFont {
  font-size: 36px;
  font-weight: bold;
}
@media (max-width: 870px) {
  .count h1 {
    font-size: 25px;
    font-weight: bold;
  }

  .counterFont {
    font-size: 25px;
    font-weight: bold;
  }
}
