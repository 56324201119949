.personal_input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth_eye_2 {
  position: absolute;
  right: 60px;
  margin-top: 42px;
  z-index: 2;
  width: 15px;
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
}

.auth_eye_2 img {
  width: 100%;
  height: 100%;
}

body[dir="rtl"] .auth_eye_2 {
  position: absolute;
  right: unset;
  left: 60px;
  margin-top: 42px;
  z-index: 2;
  cursor: pointer;
}
