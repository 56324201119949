.lang_style {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
  font-size: 14px;
  cursor: pointer;
}

.lang_style img {
  margin: 0 10px;
}

.header_shadow {
  background-color: #fff;
  box-shadow: 0 0 21px #aaa;
  height: 111px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.backdrop_admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0);
}
.navbar {
  padding: 1rem 0rem;
  margin: 0;
  justify-content: flex-start;
}
.navbar_logo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 0rem;
  margin: 0;
  /* width: 70%; */
  justify-content: flex-start;
}

.navbar-brand {
  width: 100px;
  margin: 0;
}

body[dir="rtl"] .navbar-brand {
  margin-right: 0;
}

.navbar-expand-md .navbar-collapse {
  justify-content: flex-end;
  padding-bottom: 10px;
  /* flex-basis: 100%; */
}

body[dir="rtl"] .navbar-nav {
  text-align: right;
}

.navbar-nav .navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link {
  color: #707070;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #707070;
}
.hamburger-pointer {
  cursor: pointer;
  margin: 0 10px;
}
.hamburger-button {
  width: 35px;
  height: 5px;
  border-radius: 10px;
  background-color: #686868;
  margin: 6px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.logo_tebx {
  width: 97px;
}
.line_logo {
  width: 1px;
  height: 50px;
  margin: 0 20px;
  background-color: #707070;
}
.navbar .navbar-toggler:not(:disabled):not(.disabled) {
  background: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.image_logo_new {
  max-width: 375px;
  width: 375px;
}
@media (max-width: 930px) {
  .image_logo_new {
    max-width: 300px;
    width: 300px;
  }
}
@media (max-width: 821px) {
  .image_logo_new {
    max-width: 200px;
    width: 200px;
  }
}

.navbar .navbar-toggler {
  padding: 0.25rem 0.5rem;
}
.flex_menu {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  width: 100%;
}
.dinamic_main {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.admin_support_section {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.header_notif {
  width: 18px;
}
.sup_icon {
  width: 20px;
  margin: 0 10px;
}

.header-dropdown {
  background-color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown-item {
  text-align: left;
}

.dropdown {
  /* padding-top: 10px; */
  outline: none !important;
}

body[dir="rtl"] .dropdown-item {
  text-align: right !important;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
.dropdown-menu {
  /* top: 10px !important; */
}

.dropdown-toggle::after {
  margin: 0 10px;
}
.dropdown-menu {
  position: absolute;
  left: -13px;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: inherit !important;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.navbar-nav .dropdown-menu {
  border: none;
  padding: 0 40px;
  color: #707070;
  margin-bottom: 10px;
  margin-top: -7px;
}

.navbar-nav .dropdown-toggle {
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
}

a:hover,
.dropdown-menu a {
  color: #707070 !important;
  text-decoration: none;
}
.dropdown-toggle {
  color: #707070;
}
.dropdown_admin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* color: #006d77 !important; */
}
.header_nav_link,
.header_nav_links {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  padding: 0.6rem 0.8rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  .header_nav_link {
    color: #006d77;
  }
}

.header_nav_link:hover {
}

.header_nav_links:hover,
.header_nav_links.active {
  border-bottom: 2px solid #21a6ac;
  color: #fff;
}

.header_nav_links {
}

.admin_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.admin_name {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: #006d77;
}
.navbar-nav {
  display: none;
}
.margin_en {
  margin-top: 20px !important;
}
body[dir="rtl"] .margin_en {
  margin-top: 0 !important;
}
.menu_icon {
  margin: 0 5px;
  width: 16px;
}

@media (max-width: 1199px) {
  .header_nav_link,
  .header_nav_links {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .navbar-brand {
    width: 70px;
  }
  .logo_tebx {
    width: 64px;
  }
  .line_logo {
    height: 40px;
    margin: 0 10px;
  }

  .header_logouni {
    width: 220px;
  }
  .header_logouni {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .flex_menu .lang_style {
    margin-top: 10px;
  }
  .navbar_logo {
    width: 85%;
  }
  .hamburger-pointer {
    display: none;
  }
  .hamburger-button {
    width: 25px;
    height: 2px;
  }
  .dinamic_main {
    display: unset;
    width: unset;
    justify-content: unset;
  }
  .navbar-collapse {
    width: 100%;
    position: absolute;
    top: 61px !important;
    width: 100%;
    padding: 0 10px;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .header_notif,
  .sup_icon {
    width: 15px;
  }
  .header_notif {
    margin: 0 0 10px;
  }
  .navbarSmallDeviceForAdmin {
    position: absolute;
    top: 96px !important;
    width: 100%;
    padding: 0 10px;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .admin_support,
  .header_notif {
    display: none !important;
  }
  /* .dropdown-toggle {
    padding: 0 0 10px 0 !important;
  } */
  .dropdown-menu {
    top: 45px;
    left: 0 !important;
    /* margin-top: 2px; */
  }

  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown_admin {
    margin: 10px 0 0;
  }
  .admin_name {
    font-size: 12px;
  }
  .navbar-nav {
    display: unset;
  }
}
@media (max-width: 480px) {
  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
  }
  .dropdown-menu li {
    margin-top: 2px;
  }
}

@media (max-width: 360px) {
  .header_shadow {
    height: unset;
    padding: 0 0 10px 0;
  }
  .line_logo {
    display: none;
  }
  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
