.tajah-lesson-file-row {
  position: relative;
  min-height: 70px;
  margin: 1rem 2rem;
  box-shadow: 0px 0px 1px #ddd;
  background-color: #ffffff;
  z-index: 99;
}

.tajah-lesson-file-row.root {
  box-shadow: 0px 2px 4px #ddd;
}

.tajah-lesson-file-row-actions {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.tajah-lesson-file-row-trainee {
  cursor: pointer;
  transition: background-color 0.2s;
}

.tajah-lesson-file-row-trainee:hover {
  background-color: #cccccc;
}

.tajah-lesson-file-row-active {
  background-color: #cccccc;
}
