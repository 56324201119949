.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.ip_msg_label {
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 80px 0 30px;
}

.main_box {
  padding: 30px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 50px;
}

.main_flex_box {
  justify-content: center;
}

.main_inside_box {
  width: 100%;
}

.main_input_msg {
  padding: 1rem;
}

.main_box_form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.main_label {
  margin-bottom: 20px;
}

.main_flex_label {
  color: #999;
}

.main_box_label {
  color: #777;
}

.main_box_input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50px;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.main_box_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.main_box_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.block-success-send {
  display: flex;
  width: 100%;
  height: 492px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.font-success-send {
  font-size: 20px;
}

.icon-success-send {
  font-size: 800%;
  color: darkgreen;
  font-weight: bold;
}

.alert-msg {
  color: darkred;
}

.alert-msg span {
  font-weight: bold;
}

.block-page .admin_label {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.block-page .ip_msg_label {
  margin: 20px 0;
}

@media (max-width: 991px) {
  .main_input_msg {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ip_msg_label {
    font-size: 16px;
    text-align: center;
  }
}
