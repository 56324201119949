.auth_forms_reg {
  background-color: rgb(255 255 255) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column !important;
  width: 50%;
  height: 100%;
  color: #000;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 20px;
}

.login-invalid-email-label {
  font-size: 1.6rem;
  font-weight: 600;
  color: #696969;
}

.login-invalid-email-description {
  font-size: 1rem !important;
  color: #696969;
  line-height: 1.3;
}

.auth_forms_reg input {
  color: #000;
}

.auth_form_form {
  padding: 20px 40px;
}

.auth_form_flex_col {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
}
.auth_form_flex_col label {
  /* margin: 0; */
}
.auth_label {
  font-size: 30px;
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  font-weight: bold;
  color: #046c77;
  padding: 20px 40px;
}
.auth_input_label {
  font-size: 13px;
  color: #b5b5b5;
}
.auth_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  border-bottom: 2px solid #ddd;
  height: 40px;
  margin-bottom: 50px;
  font-size: 18px;
}
.input-name-register {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  height: 100%;
  /* margin-bottom: 1rem; */
}
.input-name-register > div {
  width: 100%;
}
.menuList-country > .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  color: #000 !important;
}

.menuList-country .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: rgba(247, 247, 247, 0.34) !important;
  color: #000 !important;
}

.menuList-country .css-qiwgdb {
  color: #000 !important;
}

.menuList-country .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border: none !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-177ic5c {
  max-height: calc(48% - 155px) !important;
}

.auth_form_flex_col label[for="email"],
.auth_form_flex_col label[for="password"],
.auth_form_flex_col label[for="confirmPassword"] {
  margin-bottom: 30px;
}
.auth_form_flex_col .component_input {
  height: 40px;
}
.auth_form_flex_col .validator_input_component_border {
  border-bottom: 2px solid #ddd;
}
.auth_btn {
  width: 100%;
  /* border-radius: 10px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #006d77;
}

.auth-home-reg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: none;
}

.auth-home-reg svg {
  width: 30px;
  height: 30px;
}

body[dir="rtl"] .auth-home-reg {
  justify-content: flex-start;
}

.header-select div {
  margin-top: -1rem;
}

.header-select label {
  line-height: normal !important;
}

.register-select {
  width: 100%;
  display: flex;
  /* border-radius: 15px; */
  background-color: rgba(255, 255, 255, 0.22);
  color: #fff;
  backdrop-filter: blur(10px);
  position: relative;
  padding: 0 15px;
  height: 50px;
}

.auth_terms {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth_terms label {
  font-size: 14px;
  color: #757575;
  margin: 0;
}

.auth_terms a {
  color: #046c77;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

@media (max-width: 991px) {
  .auth_forms_reg {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .auth_forms_login {
    border-radius: 0;
  }

  .input-name-register {
    flex-direction: column;
  }

  .input-name-register > div {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .auth_forms_reg {
    justify-content: flex-start;
  }

  .auth_label {
    font-size: 20px;
    font-weight: bold;
    padding: 0 15px;
  }

  .auth_form_form {
    padding: 0 15px;
  }
}
