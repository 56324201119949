.skeleton-box {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}


.skeleton-box::after{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #e2e2e2 30%,
    #eff1f3 60%,
    #e2e2e2 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

.skeleton-margin-top-bottom {
  margin: 25px 0;
}

.backdrop_table_bank_tran {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.8);
}

.modal_wasl {
  width: 100%;
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  z-index: 2000000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  top: 50px;
}

.close:not(:disabled):not(.disabled) {
  color: red;
  font-size: 40px;
}

.close {
  margin: -20px 0;
}