.attachment-panel-dragging-activation .attachment-panel-hint-message {
  display: none;
}

.attachment-panel-dragging-activation .attachment-panel-drag-hint-message {
  display: block !important;
}

.attachment-panel-dragging-activation::after {
  content:'';
  position: absolute;
  background-color: rgba(0,0,0, 0.3);
  width: 100%;
  height: 100%;
}

.skeleton-box {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.skeleton-box::after{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #e2e2e2 30%,
    #eff1f3 60%,
    #e2e2e2 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

.skeleton-margin-top-bottom {
  margin: 25px 0;
}
