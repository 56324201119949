.my25 {
  margin: 25px 0;
}

.flexheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  width: 100%;
  justify-content: center;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .flexheader {
    flex-direction: column;
    gap: 15px;
  }
}
