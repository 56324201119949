.react-calendar {
  width: 620px;
  max-width: 100%;
  background: white;
  /* border: 1px sol#a0a096096; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  box-shadow: 2px 2px 10px #ddd;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 10px;
  padding: 15px 10px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border-radius: 16px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f085;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #24b4b998;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 1.1rem;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: #b7b7b7;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #b9b9b9;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2rem 1rem;
  max-width: 196px !important;
  color: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 1px;
  max-height: 38px !important;
  max-width: 81px !important;
  margin: 2px !important;
  background: none;
  text-align: center;
  line-height: 12px;
  font: inherit;
  font-size: 1.1rem;
  border-radius: 16px;
}
@media (max-width: 767px) {
  .react-calendar__tile {
    margin: 0px !important;
  }
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #e0dfdf;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  /* color: white; */
}

.react-calendar__tile--now {
  background: #dbdbdb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #adadad;
  /* color: white; */
}

.react-calendar__tile--hasActive {
  background: #24b3b9;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #e6e6e6 !important;
}

.react-calendar__tile--active {
  background: #e6e6e6 !important;
  /* color: white; */
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* background: #24b3b9; */
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.eventDay {
  color: #24b3b9 !important;
  /* font-weight: 600 !important; */
}
