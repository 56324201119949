.chat-bubble-header {
  height: 50px;
  width: 100%;
  background-color: #066d77;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 4px;
}

.chat-bubble-header span {
  margin-bottom: 4px;
}

.chat-bubble-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}
