.searchSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;
  padding: 4rem 0;
}

.search {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchHeading {
  font-size: 3.8rem;
  font-weight: 700;
  color: #121212;
}

.searchHeading h2 {
  font-size: 3.8rem;
  font-weight: 700;
  color: #24b3b9;
}

.searchHeading h2 span {
  color: #121212;
}

.searchSubHeading {
  font-size: 1.2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.searchInput {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.4rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}

.searchInput input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.4rem;
  font-weight: 400;
  color: #121212;
  background: none;
}

.searchInput input::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #7a7a7a;
}

.searchImage {
  width: 65%;
}

.searchImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .searchSection {
    flex-direction: column;
  }

  .search {
    width: 100%;
  }

  .searchHeading {
    font-size: 3rem;
  }

  .searchImage {
    width: 100%;
  }
}
