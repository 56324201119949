.headercontainer {
  width: 100%;
  background: linear-gradient(to bottom, #046c77 67%, white 60%);
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.headercontent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.image {
  max-width: 300px;
  height: 300px;
  border-radius: 6px;
}

.flip-star {
  transform: rotateY(180deg);
  display: flex;
  justify-content: flex-end;
}

.star-font-size::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #fff var(--percent),
    #8599b6 var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.loading-w-h {
  height: 300px;
  width: 200px;
}

.loading-w {
  width: 200px;
}

.header-container {
  gap: 20px;
  position: relative;
}

.mb40 {
  margin-bottom: 40px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.mt10 {
  margin-top: 10px;
}

.type-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 20px;
}

.d-flex {
  display: flex;
}

.type {
  border-radius: 6px;
  background: #8599b6;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: 10px 20px;
  gap: 10px;
  width: 250px;
  align-items: center;
}

@media (max-width: 1279px) {
  .type {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .headercontent {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
  .headercontainer {
    background: #046c77;
    height: 530px;
    padding-top: 20px;
  }
  .image {
    width: 250px;
    height: 330px;
  }
  .rowflex {
    justify-content: center;
  }
  .type {
    padding: 5px 15px;
  }
  .mb40 {
    margin-bottom: 20px;
  }
  .title {
    justify-content: unset;
  }
  .type-container {
    grid-gap: 0;
    gap: 0;
    width: 250px !important;
  }
}
