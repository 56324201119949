.user-card {
  background-color: #fff;
  /* padding: 10px; */
  /* border-radius: 10px; */
  padding-bottom: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  /* margin-bottom: 50px; */
  margin-top: 10px;
  /* width: 100%; */
  border: 1px solid #b7b7b77a;
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  justify-content: space-between;
}
.imgCont {
  height: 280px;
  width: 300px;
  border-bottom: 10px solid #4e8284;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 10px; */
}
.cardContantNew {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flip-star {
  /* transform: rotateY(180deg); */
  display: flex;
  /* justify-content: end; */

  border: 1px solid #f1f1f1;
  border-radius: 10px;
  width: fit-content;
  padding: 0 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.star-font-size::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #f83800 var(--percent),
    var(--defaultColor) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
}

.postion-title {
  color: #6d6d6d;
  font-size: 16px;
  display: -webkit-box;
  max-width: 270px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hovering {
  transform: scale(1.05);

  transition: 500ms ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.description {
  color: #959595;
  font-size: 12px;
  width: 270px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.name {
  /* text-align: center; */
}
.job_title {
  text-align: center;
  font-size: 14px;
  color: #03a2b2;
  font-weight: 600;
  /* height: 50px; */
}
.nums {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #046c77;
  padding: 10px 0;
}

.pip {
  color: #dadada;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
.star-dep-cat {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}
.star-dep-cat p {
  font-size: 10px;
  color: #000;
}
.num_courses {
  display: flex;
  gap: 15px;
  align-items: center;
  /* background-color: #75afb32f; */
  /* padding: 10px 25px; */
  /* border-radius: 20px; */
  /* max-width: 200px; */
  /* justify-content: center; */

  /* margin: 0 auto; */
  font-size: 12px;
}
.numContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #027777;

  white-space: nowrap;
}
.btnCont {
  width: 100%;
  /* padding-top: 20px; */
  /* border-top: 1px solid #e6e6e6; */
}
.aboutButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sapretor {
  height: 3px;
  margin: 10px 0;

  background: linear-gradient(
    270deg,
    rgba(138, 140, 140, 0.4767156862745098) 26%,
    rgba(233, 229, 229, 0.514) 68%
  );
}
body[dir="ltr"] .sapretor {
  background: linear-gradient(
    270deg,
    rgba(233, 229, 229, 0.514) 26%,
    rgba(138, 140, 140, 0.4767156862745098) 68%
  );
}
.iconClass {
  width: 16px !important;
  height: 16px !important;
  margin-top: 2px;
}

body[dir="rtl"] .iconClass {
  transform: rotateX(180deg);
}
