.slick-arrow {
  top: -2.8rem;
}
.slick-prev {
  right: 2rem !important;
  left: unset !important;
}

.slick-next {
  left: unset !important;
  right: 0;
}

.slick-arrow::before {
  color: #046c77;
}

body[dir="rtl"] .slick-prev {
  right: unset !important;
  left: 0 !important;
}
body[dir="rtl"] .slick-prev::before {
  content: "←";
}

body[dir="rtl"] .slick-next {
  left: 2rem !important;
  right: unset !important;
}

body[dir="rtl"] .slick-next::before {
  content: "→";
}
.sponsors-list .slick-arrow.slick-next,
.sponsors-list .slick-arrow.slick-prev {
  transform: rotate(0);
}
