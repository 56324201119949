.exhibition-details {
  padding: 2rem 0;
}

.exhibition-details__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.exhibition-details__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.exhibition-details__head__info {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.exhibition-details__head__exh {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.exhibition-details__head__exh-top,
.exhibition-details__head__exh-bottom {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.exhibition-details__pref {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.exhibition-details__pref-title {
  color: #046c77;
  font-size: 24px;
  font-weight: bold;
}

.exhibition-details__pref-desc {
  color: #7b7b7b;
  font-size: 16px;
  text-align: justify;
}

.exhibition-details__audios {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.exhibition-details__audios-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.exhibition-details__audios-item-title {
  font-size: 16px;
  color: #777777;
}

.exhibition-details__audios-item audio {
  width: 100%;
}

.exhibition-details__prices {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.exhibition-details__prices-item {
  width: calc(100% / 3);
}

.exhibition-details__files {
  display: flex;
  gap: 1rem;
  position: relative;
}
.exhibition-details__files > div {
  position: relative;
}
.exhibition-details__files > div:not(:first-of-type)::after {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #707070;
  content: "";
  left: -0.5rem;
}

body[dir="rtl"] .exhibition-details__files > div:not(:first-of-type)::after {
  left: unset;
  right: -0.5rem;
  top: 0;
}

.exhibition-details__documents,
.exhibition-details__links {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
}
.exhibition-details__documents__lists,
.exhibition-details__links__lists {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  max-height: 170px;
  overflow-y: auto;
}

.exhibition-details__documents-item {
  width: calc(100% / 2 - 0.5rem);
}
.exhibition-details__links-item {
  width: 100%;
  cursor: pointer;
}

.exhibition-details__documents-title,
.exhibition-details__links-title {
  color: #777777;
  font-size: 20px;
  margin: 0;
}

.exhibition-details__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.exhibition-details__tables__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exhibition-details__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.exhibition-details__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.exhibition-details__table__actions-btn-updated {
  position: relative;
  background: none;
  border: none;
  padding: 0;
}

.exhibition-details__table__actions-btn-updated::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #b91414;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.exhibition-details__table-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exhibition-details__table-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.exhibition-details__table__users__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  padding: 0 1rem;
}

.exhibition-details__table__users__actions-btn {
  background-color: #046c77;
  color: #ffffff;
  padding: 0 1rem;
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
}

.exhibition-details__table__users__actions-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.exhibition-details__table__users__actions-btn.reject {
  background: transparent;
  border: 1px solid #ff2424;
  color: #b92424;
}

.exhibition-details__carousel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.exhibition-details__carousel-title {
  color: #777777;
  font-size: 20px;
}
.exhibition-details__slider {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-height: 300px;
}

.exhibition-details__slider-title {
  font-size: 20px;
  color: #777777;
  font-weight: bold;
}
.exhibition-details-separator {
  background-color: #707070;
  width: 1px;
}

/**** custom scrollbar ****/
.exhibition-details__documents__lists::-webkit-scrollbar {
  width: 8px;
}
.exhibition-details__documents__lists::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.exhibition-details__documents__lists::-webkit-scrollbar-thumb {
  background-color: #046c77;
  border-radius: 4px;
}
.exhibition-details__documents__lists::-webkit-scrollbar-thumb:hover {
  background-color: #046c77;
}

.exhibition-details__links__lists::-webkit-scrollbar {
  width: 8px;
}
.exhibition-details__links__lists::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.exhibition-details__links__lists::-webkit-scrollbar-thumb {
  background-color: #046c77;
  border-radius: 4px;
}
.exhibition-details__links__lists::-webkit-scrollbar-thumb:hover {
  background-color: #046c77;
}

.exhibition-details__table-payment-method {
}

.exhibition-details__table-payment-method--banktransfer {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.exhibition-details__no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

.exhibition-details__tables-booths,
.exhibition-details__tables-users {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.user-table__table-description {
  white-space: normal;
}

@media only screen and (max-width: 768px) {
  .exhibition-details__tables__header {
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
  }

  .exhibition-details__head {
    flex-direction: column;
    align-items: flex-start;
  }

  .exhibition-details__head__info {
    flex-direction: column;
    align-items: flex-start;
    order: 1;
    width: 100%;
  }
  .exhibition-details__head__options {
    align-self: flex-end;
  }

  .exhibition-details__audios {
    flex-direction: column;
  }

  .exhibition-details__audios-item {
    width: 100%;
  }
  .exhibition-details__prices {
    flex-wrap: wrap;
  }
  .exhibition-details__prices-item {
    width: 100%;
  }

  .exhibition-details__files {
    flex-direction: column;
  }
  .exhibition-details__files > div,
  .exhibition-details__documents-item {
    width: 100%;
  }
  .exhibition-details__files > div::after {
    content: unset !important;
  }
}

@media only screen and (max-width: 480px) {
  .exhibition-details__head__exh-top,
  .exhibition-details__head__exh-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}
