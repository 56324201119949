.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgb(33 33 33 / 75%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  width: 60%;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* align-items: center; */
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.modal__header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #00254a;
}

.modal__content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal__content p {
  font-size: 1rem;
  color: #555555;
  font-weight: 400;
  line-height: 1.5;
}

.modal__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
}

.modal__buttons button {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.modal__buttons__accept {
  background-color: #126d77;
  color: #ffffff;
}

.modal__buttons__reject {
  background-color: transparent;
  border: 1px solid #126d77;
  color: #126d77;
}

@media (max-width: 768px) {
  .modal__container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .modal__container {
    width: 100%;
  }
}
