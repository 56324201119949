.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 50px;
}

.input-disable {
  background-color: #fafafa;
}

.textarea {
  width: 100%;
  height: 100%;
  font-size: 17px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  resize: none;
  overflow: hidden;
}

.icon {
  color: white;
  height: 40px !important;
  width: 40px !important;
  background-color: #066d77;
  padding: 10px;
  margin: 0 5px;
  border-radius: 4px;
  rotate: 180deg;
}

.disabledicon {
  color: white;
  height: 40px !important;
  width: 40px !important;
  background-color: #c7c7c7;
  padding: 10px;
  margin: 0 5px;
  border-radius: 4px;
  rotate: 180deg;
  cursor: not-allowed;
}

body[dir="ltr"] .icon {
  rotate: 0deg;
}

body[dir="ltr"] .disabledicon {
  rotate: 0deg;
}
