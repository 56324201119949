.auth_form {
  display: flex;
  width: 100%;
  color: #fff;
}

.homeBtn {
  display: none;
}

body[dir="rtl"] .auth_form {
  text-align: right;
}

.auth_soical {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(132, 132, 132, 0.2);
  padding: 25px 25px 0 25px;
  border-radius: 15px;
  position: absolute;
  width: 325px;
  min-height: 400px;
  left: 480px;
}

body[dir="rtl"] .auth_soical {
  right: 480px;
}

.auth_box {
  display: flex;
  width: 830px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.auth_main_box {
  background-image: url("/src/assets/image/pat-bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -2;
  overflow-y: hidden;
}

.blur-image {
  background-color: rgb(0 8 8 / 80%) !important;
  backdrop-filter: blur(10px);
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.auth-card {
  width: 100%;
  margin: 2.4rem auto;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* background-image: url("/src/assets/image/homepage.png"); */
  /* background-image: url("/src/assets/image/imagebg.png"); */

  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  color: black;
  border-radius: 15px !important;
}
.auth_forms_login,
.auth_forms_reg {
  border-radius: 4px !important;
}

.auth-inside-card {
  height: 100%;
}

body[dir="ltr"] .auth-inside-card {
  flex-direction: row-reverse;
}

@media (max-width: 991px) {
}
@media (max-width: 991px) {
  .image-style {
    display: none;
  }

  .homeBtn {
    display: block;
    position: absolute;
    top: 15px;
  }

  body[dir="rtl"] .homeBtn {
    left: 15px;
  }

  body[dir="ltr"] .homeBtn {
    right: 15px;
  }

  .auth_soical {
    left: 410px;
  }

  body[dir="rtl"] .auth_soical {
    right: 410px;
  }
  .auth_forms_reg {
    margin: auto;
    width: 90%;
    border-radius: 0;
  }

  .auth-inside-card {
    margin-top: 4rem;
  }
}

@media (max-width: 767px) {
  .auth_forms_reg {
    margin: auto;
    width: 100%;
  }
  .auth_soical {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
    left: 0;
    padding: 10px 45px;
  }
  body[dir="rtl"] .auth_soical {
    right: 0px;
  }

  .auth_main_box {
    align-items: baseline;
    height: 100%;
    background-repeat: no-repeat;
  }

  .auth_box {
    width: 100%;
    flex-direction: column;
  }
  .auth-card {
    border-radius: 0 !important;
    margin: 0;
    height: 100vh;
  }

  .auth-inside-card {
    margin-top: 0;
  }
}
