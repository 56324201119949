.card-counter {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px #ddd;
}

.card-counter:hover {
  background-image: linear-gradient(to bottom, #fff, rgba(3, 108, 119, 0.1));
}

.link-cursor {
  cursor: pointer;
}

.card-top {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.card-image {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 10px;
}

.card-image img {
  width: 100%;
  height: 100%;
}

.card-label {
  font-size: 14px;
  color: rgb(3, 108, 119);
  font-weight: 600;
}

.card-number {
  font-size: 1.6rem;
  font-weight: bold;
  color: rgb(20, 148, 154);
  margin-bottom: 10px;
}

.card-link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: rgb(3, 108, 119);
}

.arrow {
  width: 10px;
  height: 10px;
  margin-top: 5px;
}

.arrow img {
  width: 100%;
  height: 100%;
}
