.auth-email-verification-container {
  width: 50%;
  height: 100%;
  min-height: 500px;
  background-color: #fff;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-email-verification-title {
  font-size: 32px;
  color: #046c77;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 3px;
  margin: 0;
  line-height: 27px;
  padding-bottom: 20px;
}

.auth-email-verification-body {
  margin-top: 20px;
  font-size: 15px;
  padding: 0 25px;
}

@media (max-width: 991px) {
  .auth-email-verification-container {
    width: 100%;
  }
}
