.back {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  padding-bottom: 0.4rem;
}
