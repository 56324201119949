.courses-coupon-modal {
  display: flex;
  width: 100%;
  height: 200px;
  position: fixed;
  z-index: 1000000000;
  justify-content: center;
  align-items: center;
  top: 50%;
}

.courses-coupon-box {
  display: flex;
  flex-direction: column;
  padding: 35px 50px;
  justify-content: space-between;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.courses-coupon-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.courses-coupon-modal-button {
  width: 70px;
  padding: 5px 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.courses-coupon-yes {
  background-color: #d20101;
  color: #fff;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 2px solid #006d77;
  border-radius: 10px;
  color: #006d77;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.containerContentFile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerButtonFile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-fade {
  text-align: center;
  animation: fadeIn 1s infinite alternate;
  -webkit-animation: fadeIn 0.5s infinite alternate;
}

.loading-fade img {
  width: 70%;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
}
