.addUsersByExcel {
  width: 750px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.addUsersByExcel_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.addUsersByExcel_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #046c77;
}

.addUsersByExcel_close {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.addUsersByExcel_close img {
  width: 100%;
  height: 100%;
}

.addUsersByExcel-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin-top: 20px;
}

.addUsersByExcel-actions-btn {
  padding: 1rem 2rem;
}
