.program {
	margin: 30px 0;
}

.head_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
}

.head_lable {
	font-size: 22px;
}

.text-dark {
	color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
	color: #1d2124 !important;
}

.head_view {
	font-size: 16px;
	display: flex;
}

.arrow_icon {
	width: 8px;
	margin: 3px 10px 0;
}
.arrow_seeall {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
	transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	margin-top: -2px;
}

.arrow_icon {
	width: 8px;
	margin: 3px 10px 0;
}

.card_grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.card_grid .course-details > img {
	width: 100%;
}

.no-record {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;
}

@media (max-width: 1199px) {
	.head_lable {
		font-size: 18px;
	}
	.head_view {
		font-size: 14px;
	}
}

@media (max-width: 991px) {
	.head_lable {
		font-size: 16px;
	}

	.card_grid {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}
}
