.events-list {
  padding: 2rem 0;
}

.events-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.note_table {
  white-space: pre-line;
}
