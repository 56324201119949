.delete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.85);
}

.delete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000000;
  background: rgba(0, 0, 0, 0.3);
}

.box-width {
  width: 90%;
}

.lesson-text-content-panel {
  margin-top: 20px;
}

.lesson-text-content-panel {
  width: 100%
}

@media (max-width:991px) {
  .box-width {
      width: 100%;
  }
}
