.trainer_section {
  background-color: #efefef;
}
.trainers {
  margin: 30px 0;
}

.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}

.head_view {
  font-size: 16px;
  display: flex;
}

.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.arrow_icon {
  width: 8px;
  margin: 3px 10px 0;
}

.carousel {
  /* margin: 0px auto 0px;
	padding: 0 30px; */
}
.carousel .carousel-item {
  align-items: unset !important;
  overflow: hidden;
}
.carousel .thumb-wrapper {
  margin: 5px;
  text-align: left;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.carousel-control-prev,
.carousel-control-next {
  height: 20px;
  width: 20px;
  background: none;
  margin: auto 0;
  border-radius: 50%;
}
.carousel-control-prev .i,
.carousel-control-next .i {
  font-size: 18px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
}

.carousel-control-next .i {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.carousel-control-prev i {
  margin-left: -3px;
}
.carousel-control-next i {
  margin-right: -3px;
}
.carousel-indicators {
  bottom: -50px;
}
.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 4px;
  border: none;
}
.carousel-indicators li {
  background: #ababab;
}
.carousel-indicators li.active {
  background: #555;
}
.i {
  width: 18px;
}
.trainers_carousel {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }
  .head_view {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }
}

.grid-card {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
