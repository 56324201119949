.admin_label {
  display: flex;
  margin: 30px 0;
}

.counter_number {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.counter_number_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 30px;
}

.counter_number_links {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  justify-content: space-between;
}

.counter_number_link {
  color: #5060ef;
}
.arrow_admin {
  margin: 0 10px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_admin {
  margin: 0 10px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

@media (max-width: 991px) {
  .counter_number_link {
    font-size: 14px;
  }

  .admin_label,
  .counter_number,
  .text_num_b {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .counter_number_link {
    font-size: 10px;
  }

  .admin_label,
  .counter_number,
  .text_num_b {
    font-size: 14px !important;
  }

  .counter_number_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    gap: 30px;
    margin: 30px;
    font-size: 12px;
  }
}
