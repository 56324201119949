.table-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.table-actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.table-actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
