.footerSection {
  margin: 2rem 0;
  position: relative;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footerSection::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url("../../../../assets/icons/Group-21.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.footerContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  text-align: center;
}

.footerContent h2 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
}

.footerContent p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}

.footerImage {
  width: 50%;
}

.footerImage img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0;
    background: #24b3b9;
    padding: 2rem 0;
  }

  .footerSection::before {
    display: none;
  }

  .footerContent {
    width: 100%;
    padding: 2rem 0;
  }

  .footerImage {
    display: none;
  }
}
