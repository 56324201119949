#app {
  padding: 40px;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent !important;
  font-size: 17px;
  background: transparent !important;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 40px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: none;
  padding: 0 4px;
  background: transparent;
}

span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  display: inline-block;
  padding: 2px 6px;
  margin: 0 5px 5px 5px;
  cursor: default !important;
  border-radius: 2px;
  min-width: 100px;
  width: 100%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

span.tag-wrapper {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #9c9c9c;
  margin: 0 5px;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  outline: none;
  font-size: 20px;
  color: darkred;
  font-weight: 900;
}

div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 2px solid #006d77;
  border-radius: 10px;
  color: #006d77;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.container-fluid {
  max-width: 1280px;
  padding: 0 0px;
}
body[dir="rtl"] .form-check-input {
  margin-right: 0;
}
@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
  .personal_info_top {
    font-size: 14px;
  }
  .personal_info_bottom {
    font-size: 10px;
  }

  .personal_info_card_st {
    margin: 20px 0;
  }

  .personal_info_card {
    padding: 10px 20px;
  }
  .perosnal_text_img {
    font-size: 14px;
  }
  .course_dimage {
    width: 70px;
    height: 70px;
  }
  .course_browse {
    width: 30px;
    height: 30px;
    margin-left: 40px;
    margin-top: -20px;
  }
  .personal_img_text {
    font-size: 12px;
    margin: 0 9px;
  }
  .personal_label {
    font-size: 12px;
  }

  .edit-course__form-group {
    flex-wrap: wrap;
  }
  .edit-course__form-group > div {
    width: 100% !important;
  }
  .edit-course__form-group > div input {
    height: 50px !important;
    margin-bottom: 0 !important;
  }
  .personal_info_label {
    margin: 0 !important;
  }
  .personal_info_card {
    width: 100% !important;
    padding: 20px !important;
    border-radius: 10px !important;
  }

  .course_img {
    flex-direction: column;
  }
  .course_img .personal_img_text {
    margin: 20px 0 !important;
  }

  body[dir="rtl"] .course_browse {
    float: right;
  }
}

@media (max-width: 1199px) {
  .personal_info_label {
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
  }
  .personal_info_top {
    font-size: 25px;
  }

  .personal_info_card {
    width: 80%;
  }
}
@media (max-width: 991px) {
  .form-check-label {
    font-size: 12px;
  }
  .personal_info_label {
    margin: 0;
  }
  .personal_info_bottom {
    font-size: 14px;
  }

  .personal_info_top {
    font-size: 20px;
  }
  .personal_info_card {
    width: 100%;
  }
  .perosnal_text_img {
    font-size: 20px;
  }
  .personal_img_text {
    font-size: 10px;
  }
  .personal_label {
    font-size: 10px;
  }
}

.personal_info_top {
  display: flex;
  font-size: 30px;
}
.min-hight-78 {
  min-height: calc(78vh - 20px);
}

.min-hight-78-200 {
  min-height: calc(78vh - 189px);
}

.personal_info_bottom {
  display: flex;
}

.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.personal_info_label {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
}

.personal_info_card_st {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.personal_info_card {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 1px solid #ccc;
  background-color: #fffbfb;
  border-radius: 20px;
  padding: 50px 80px;
}

.perosnal_text_img {
  display: flex;
  font-size: 25px;
}

.course_img {
  display: flex;
  margin: 30px 0 0;
}

.course_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.course_browse {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006d77;
  border-radius: 50%;
  float: left;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.personal_browse_img {
  width: 50%;
}

.personal_img_text {
  text-align: initial;
  margin: 20px;
}
.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.personal_form {
  margin-top: 50px;
}

.personal_label {
  display: flex;
}

.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
  padding: 10px 0 !important;
}

.admin_coupon_radio {
  display: flex;
  font-size: 14px;
  width: 40%;
}
.admin_coupon_radio {
  justify-content: space-between;
}

.form-check-label {
  padding: 0 20px;
}
