.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  gap: 16rem;
  padding: 4rem 0;
}

.about {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about h2 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #121212;
}

.about span {
  font-size: 2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.aboutSubHeading {
  font-size: 1.2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.aboutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
  border: none;
  width: fit-content;
  border-radius: 16px;
  margin-top: 1rem;
}

.aboutButton span {
  font-size: 1.4rem;
  color: #fff;
}

.aboutImage {
  /* padding: 8rem; */
  width: 60%;
}

.aboutImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .aboutSection {
    flex-direction: column;
  }

  .about {
    width: 100%;
  }

  .aboutImage {
    padding: 0;
    width: 100%;
  }
}
