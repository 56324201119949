.personal_label {
  display: flex;
}
.personal_input_in {
  width: 80%;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
}
.chat_bubble_registeration_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #006d77;
  color: #fff;
  padding: 10px 0px 10px 0px;
  border-radius: 15px;
  /*margin: 0 10px;*/
  width: 100%;
}

.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px 0 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.chat-login-container {
  padding: 15px 10px;
  background-color: #fff;
  box-shadow: 3px 6px 10px rgb(225, 225, 225);
  border-radius: 16px;
  margin: 15px 10px;
}

.chat-login-container::before {
  z-index: 100;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: 12px;
  left: 26%;
  right: unset;
  box-sizing: border-box;
  border: 7px solid #fff;
  border-color: transparent #fff #fff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

.content-container {
  display: flex;
}

.date {
  font-size: 12px;
  color: #bdbdbd;
}

.label {
  font-size: 13px;
}

.btn {
  display: flex;
  justify-content: flex-end;
}
