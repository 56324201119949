.events-list {
  padding: 2rem 0;
}

.events-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.note_table {
  white-space: pre-line;
}
.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chartTitle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
