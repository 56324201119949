.progress-bar-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.progress-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  min-width: fit-content;
  width: 200px;
}

.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.progress-bar-progress {
  width: 100%;
  height: 0.4rem !important;
  margin-top: 0.4rem;
}

.progress-percent {
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  min-width: fit-content;
  width: 50px;
}
