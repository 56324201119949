/* Footer */
.footer {
  width: 100%;
  padding: 20px 0 20px 0;
  background: linear-gradient(90deg, rgb(255, 255, 255) 9%, #006d7748 76%);
  /* color: #fff !important; */
  font-weight: bold;
  display: flex;
  justify-content: center !important;
  align-content: center;
  align-items: center;
  margin-top: 50px;
}
body[dir="rtl"] .footer {
  background: linear-gradient(90deg, #006d7748 9%, rgb(255, 255, 255) 76%);
}
.footerMainCont {
  display: flex;
}
.list_UL {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer_col_cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer_hide {
  width: 100%;
  padding: 20px 0 20px 0;
  background-color: #1d2f54;
  color: #fff !important;
  font-weight: bold;
  display: none;
  justify-content: center !important;
  align-content: center;
  align-items: center;
  margin-top: 50px;
}

.footer_nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Nav {
  background-color: #fff !important;
  display: block;
  margin-bottom: 1%;
  padding: 2% 8%;
  color: #fff;
  font-weight: bold;
}

.NavLink_footer {
  color: #939393;
  margin: 0 1%;
  text-decoration: none;
}

.footer_link {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.footer_nav_link {
  display: flex;
  justify-content: flex-end;
  width: 55%;
}

.footer_nav_copy {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #8b8f8f;
  white-space: nowrap;
}

.footer_content {
  background: #fff;
  padding: 30px 0 10px 0;
  color: #fff;
  margin-top: 50px;
}
.footer_content_hide {
  background: #2e3e5f;
  padding: 30px 0 10px 0;
  color: #fff;
  display: none;
}

.footer_content .menu h3 {
  font-size: 20px;
  margin-bottom: 30px;
}
.footer_content .menu p {
  font-size: 14px;
  margin-bottom: 60px;
}
.footer_content .social_media {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
}
.footer_content .social_media li {
  display: inline-block;
  padding: 0 12px;
  cursor: pointer;
}
.footer_content .social_media li:first-child {
  padding-left: 0;
}
body[dir="rtl"] .footer_content .social_media li:first-child {
  padding-right: 0;
  padding-left: 12px;
}

.footer_content .menu .links li {
  padding: 3px 0;
}
.footer_content .menu .links .NavLink_footer {
  font-size: 14px;
  color: #939393;
  cursor: pointer;
}
.footer_content .menu input[type="text"] {
  display: inline-block;
  width: 78%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  color: #fff;
}
.footer_content .menu input[type="text"]::placeholder {
  color: rgb(255 255 255 / 27%);
}
.footer_content .menu input[type="submit"] {
  background: #126e77;
  color: #fff;
  border: none;
  padding: 10px 12px;
  border-radius: 15px;
  cursor: pointer;
}
.footer_content .menu .clearfix {
  margin-bottom: 50px;
}
.footer_content .menu h5 {
  font-size: 18px;
}
.footer_content .menu.signup h5 {
  font-size: 18px;
}
.footer_content .payment_mothed {
  padding: 20px 0px;
}

.facebook-icon:hover {
  color: #4267b2;
}

.twitter-icon:hover {
  color: #00acee;
}

.instagram-icon:hover {
  color: #c13584;
}

.youtube-icon:hover {
  color: #ff0000;
}

.facebook-icon,
.twitter-icon,
.instagram-icon,
.youtube-icon {
  font-size: 22px;
}

.social_media {
  margin-top: 20px !important;
  display: flex;
}
.social_media_spactial {
  display: flex;
  margin-top: 20px !important;
  gap: 10px;
}

.footer_content {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.logo-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-bottom: 50px; */
}
@media (max-width: 768px) {
  .logo-flex {
    margin-right: 10px;
  }
  body[dir="ltr"] .logo-flex {
    margin-left: 10px;
  }
}

.logo-flex img {
  width: 200px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}
.footer-grid h3 {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.footer-grid li {
  font-size: 16px;
  color: #939393;
}
.payment_mothed {
  display: flex;
}

.payment_mothed img {
  width: 80px;
  margin: 10px;
}

@media (max-width: 1119px) {
  .footer_nav_link {
    display: flex;
    width: 100%;
  }

  .footer_nav_copy {
    display: flex;
    width: 60%;
  }
}
@media (max-width: 991px) {
  .footer_nav_link {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
  }

  .footer_link {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .footer_nav_link {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    flex: 1;
  }
  .logo-flex {
    flex-direction: row;
  }
  .footer-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer_content {
    padding: 50px 15px;
  }
  .footer_link {
    gap: 20px;
  }
}

/* End Footer */
