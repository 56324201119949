/*{*/
/*    box-sizing: border-box ;*/
/*}*/

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form-popup {
  display: none;
  position: relative;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 0;
}

/* Add styles to the form container */
.form-container-chat {
  max-width: 410px;
  z-index: 10000;
  padding: 6px;
  border-radius: 4px;
  background-color: #fff;
  position: fixed;
  bottom: 100px;
  right: unset;
  left: 20px;
  min-height: 300px;
}

body[dir="rtl"] .form-container-chat {
  right: 20px;
  left: unset;
}

/* Full-width textarea */
.form-container-chat textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
}

.form-container-chat::before {
  z-index: 10;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: -2px;
  left: 0;
  right: 2px;
  box-sizing: border-box;
  border: 12px solid #fff;
  border-color: transparent #fff #fff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

body[dir="ltr"] .form-container-chat::before {
  left: 30px;
  right: unset;
}

/* When the textarea gets focus, do something */
.form-container-chat textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container-chat .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container-chat .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container-chat .btn:hover,
.open-button:hover {
  opacity: 1;
}

.left,
.right {
  display: block;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  word-break: break-word;
}

.left {
  float: left;
  margin-left: 10px;
  color: #000;
}

.right {
  float: right;
  margin-right: 10px;
  color: #000;
}

.date {
  font-size: 10px;
  color: #bdbdbd;
}

.chat-circle {
  position: fixed;
  /* right: 15px; */
  bottom: 10px;
  /* width: 60px;
  height: 60px;
  background: #006d77;
  border: 3px solid white;
  box-shadow: 3px 6px 10px rgb(178, 178, 178); */
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  padding-inline-start: 15px;
  align-items: center;
  z-index: 100000;
}

body[dir="ltr"] .chat-circle {
  /* left: 20px; */
  /* right:0 */
}

.chat-bubble-header {
  height: 40px;
  border-bottom: solid;
  border-width: 1px;
  width: 100%;
  height: 100%;
  border-color: #dbe5ed;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px;
  background-color: #e4fffc;
}

@media only screen and (max-width: 768px) {
  .form-container-chat {
    display: block;
    width: calc(100% - 30px);
    left: 15px;
  }
}

.live-chat-container {
  width: 80%;
  padding: 15px 10px;
  background-color: #fff;
  box-shadow: 3px 6px 10px rgb(225, 225, 225);
  border-radius: 16px;
  margin: 15px 10px;
  display: flex;
  align-items: center;
}

.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.live-chat-container::before {
  z-index: 10000;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: -4px;
  left: 26%;
  right: unset;
  box-sizing: border-box;
  border: 7px solid #fff;
  border-color: transparent #fff #fff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

.chaticon {
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding-bottom: 0.4rem;
  width: 70px;
  height: 70px;
}

.chatBubbleRegisteration {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.warningMessage {
  min-width: 350px;
}

.content-container {
  display: flex;
  align-items: center;
}

.message {
  overflow: auto;
  height: 350px;
  width: 400px;
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .message {
    width: unset;
    max-width: 400px;
  }
}

.relative {
  position: relative;
}
