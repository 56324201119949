.titleAddFile {
  color: #046c77;
  font-size: 24px;
  margin-bottom: 0;
}

.delete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000000;
  background: rgba(0, 0, 0, 0.3);
}

.fix_delete_style {
  top: 40%;
}

.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 40px; */
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.label_container {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.search_input {
  margin-bottom: 30px;
  width: 60%;
  display: flex;
  position: relative;
  align-items: center;
  height: 40px;
  border: 1px solid #d9d6d6;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.search_box_input .icon {
  position: absolute;
  right: 10px;
  top: 25%;
  z-index: 99;
  cursor: pointer;
}

.search_input svg {
  width: 3rem !important;
}

.search_input input {
  outline: none;
  border: none;
  width: 100%;
}

body[dir="rtl"] .search_box_input .icon {
  left: 10px;
  right: unset;
}

.admin_button_flex {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}

.admin_button_green {
  background-color: #24b3b9;
  border-color: rgb(0, 109, 119);
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  min-width: 50px !important;
  width: 50px;
  height: 50px;
}

.filterIcon-blocked {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #24b3b9;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}

.admin_button_green:hover {
  color: white !important;
}

.flex-addbtn-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.font_awesome_edit {
  font-size: 20px !important;
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 480px) {
  .label_container {
    flex-direction: column;
  }
}
