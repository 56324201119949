.name_size {
  font-size: 1rem;
}
.title_size {
  font-size: 1rem;
}

@media screen and (max-width: 992px) {
  .name_size {
    font-size: 0.75rem;
  }
  .title_size {
    font-size: 0.7rem;
    width: 60%;
  }
}
