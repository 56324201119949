.delete-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000000;
  background: rgba(0, 0, 0, 0.3);
}

.fix_delete_style {
  top: 40%;
}

.not-data-container {
  padding: 20px 0;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.main_label {
  color: #046c77;
  font-size: 22px;
}

.main_label li {
  font-size: 1rem;
}

.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.admin_label {
  display: flex;
  margin: 30px 0;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.label_container {
  display: flex;
  width: 100% !important;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: flex-end;
}

.search_input {
  width: 60%;
  display: flex;
  position: relative;
  align-items: center;
  height: 40px;
  padding: 1px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.search_box_input .icon {
  position: absolute;
  right: 10px;
  top: 25%;
  z-index: 99;
  cursor: pointer;
}

.search_input input {
  outline: none;
  border: none;
  width: 100%;
}

body[dir="rtl"] .search_box_input .icon {
  left: 10px;
  right: unset;
}

.red_text {
  color: #d20101;
  cursor: pointer;
}

.green_text {
  color: green;
  cursor: pointer;
}

.table {
  width: 100% !important;
}

.block-request-message {
  white-space: nowrap;
}

@media (max-width: 991px) {
  .label_container {
    flex-direction: column;
  }
}
