.main-box__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-box__head-title {
  font-size: 24px;
  font-weight: 400;
  color: #046c77;
  z-index: unset;
}

.main-box__head__actions {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-box__head__actions::-webkit-scrollbar {
  display: none;
}

.main-box__head__action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 2px solid #24b3b9;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
  min-width: 50px;
}

.main-box__head__action-new-button {
  background-color: #24b3b9;
  border: none;
  width: 200px;
  min-width: 200px;
  color: #fff;
}

.main-box__head__action-first-theme {
  background-color: #24b3b9;
  border: none;
  width: 200px;
  color: #fff;
  min-width: 200px;
}

.main-box__head__action-second-theme {
  background-color: #046c77;
  border: none;
  width: 200px;
  color: #fff;
  min-width: 200px;
}

.main-box__head__action-last-theme {
  background-color: #8599b6;
  border: none;
  width: 200px;
  color: #fff;
  min-width: 200px;
}

.main-box__head__action-add {
  background-color: #24b3b9;
  border: none;
}
.main-box__head__action-edit {
  border-color: #d1d626;
}
.main-box__head__action-pdf {
  background-color: #b91414;
  border: none;
}
.main-box__head__action-xls {
  background-color: #138d5f;
  border: none;
}
.main-box__head__action-export {
  background-color: #8599b6;
  border: none;
}
.main-box__head__action-file {
  background-color: #2e3e5f;
  border: none;
}

.main-box__head__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

/**** Start Switch Style ****/
.main-box__head__switch input[switch] {
  display: none;
}
.main-box__head__switch input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 4.7rem;
  height: 2rem;
  background-color: #ddd;
  background-image: none;
  border-radius: 2rem;
  padding: 0.1666666667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
  font-family: inherit;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin: 0;
}
.main-box__head__switch input[switch] + label:before {
  /* Label */
  text-transform: uppercase;
  color: #b7b7b7;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-family: FontAwesome, inherit;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1.22rem;
  position: absolute;
  right: 0.2166666667rem;
  margin: 0.2166666667rem;
  text-align: center;
  min-width: 1.6666666667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.main-box__head__switch input[switch] + label:after {
  /* Slider */
  content: "";
  position: absolute;
  left: 0.3rem;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 2rem;
  height: 1.4rem;
  width: 1.4rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
}
.main-box__head__switch input[switch]:checked + label {
  background-color: lightblue;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.15)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.15),
    rgba(0, 0, 0, 0.2)
  );
}
.main-box__head__switch input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 0.2166666667rem;
}
.main-box__head__switch input[switch]:checked + label:after {
  left: 3rem;
  background-color: #f7f7f7;
}

.main-box__head__switch input[switch="bool"]:checked + label {
  background-color: #046c77;
}
.main-box__head__switch input[switch="bool"]:checked + label:before {
  color: #fff !important;
}
.main-box__head__switch input[switch="bool"] + label {
  background-color: #c7c7c7;
}
.main-box__head__switch input[switch="bool"] + label:before {
  color: #fff !important;
}

.main-box__head__switch input[disabled] + label {
  cursor: not-allowed;
  opacity: 0.5;
}

/**** End Switch Style ****/

@media only screen and (max-width: 768px) {
  .main-box__head__actions {
    width: 100%;
  }
  .main-box__head {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}
