.reorder-program-courses-modal {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  width: 100%;
  max-width: 900px;
}

.reorder-program-courses-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reorder-program-courses-header h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #046c77;
}

.reorder-program-courses-form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.reorder-program-courses-form-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.reorder-program-courses-form-content-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #046c77;
  border-radius: 0.5rem;
}

.reorder-program-courses-form-content-item:hover {
  background-color: #e6f7f8;
}

.reorder-program-courses-form-content-item span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #046c77;
}

.reorder-program-courses-form-content-item img {
  width: 2rem;
  height: 2rem;
}

.dragged-over {
  opacity: 0.5;
  background-color: #e6f7f8;
}

.reorder-program-courses-form-content-error {
  color: #ff0000;
  font-size: 1.2rem;
  font-weight: 500;
}

.reorder-program-courses-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
