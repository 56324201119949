.sponsors-slider-item {
  text-align: center;
}

.sponsors-slider-item-image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.sponsors-slider-item-image img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.sponsors-slider-item-name {
  color: #046c77;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.sponsors-slider-item-booth {
  color: #5d5d5d;
  font-size: 16px;
  text-align: center;
}
