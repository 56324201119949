.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-switch-word {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.toggle-switch-field {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  margin: 0;
}

.toggle-switch-field input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
}

.toggle-switch-field span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c7c7c7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 28px;
  width: auto;
  height: auto;
}

.toggle-switch-field span:before {
  position: absolute !important;
  content: "" !important;
  height: 20px;
  width: 20px;
  left: 4px !important;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
  top: unset !important;
}

.toggle-switch-field input:checked+span {
  background-color: #046c77;
}

.toggle-switch-field input:focus+span {
  box-shadow: 0 0 1px #046c77;
}

.toggle-switch-field input:checked+span:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}


.toggle-label {
  font-size: 13px;
  /* Adjust the font size as needed */
  margin-inline-end: 5px;
  /* Adjust the margin as needed */
}

.toggle-text {
  margin-inline-end: 15px;
  color: #7a7a7a;
}