.title {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}
.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calenderCont {
  width: fit-content;
  /* box-shadow: 2px 2px 10px #ddd; */
  border-radius: 10px;
  /* padding-bottom: 10px; */
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.event__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;
}
.cardCont {
  display: flex;
  gap: 20px;
  padding: 10px;
  border-radius: 16px;
  /* background-color: #e7e7e7a1; */
  box-shadow: 2px 2px 10px #ddd;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  justify-content: space-between;
}
.img_title {
  display: flex;
  gap: 20px;
  width: 80%;
}
.imgCont {
  width: 150px;
  height: 150px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  width: 150px;
  height: 150px;
}
.contentCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 80%;
}
.contentCont h2 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(75 85 99);
}
.contentCont h4 {
  font-size: 12px;
  font-weight: 600;
  color: rgb(75 85 99);
}
.date_timeCont {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-inline-end: 20px;
}
.date_time {
  font-size: 14px;
}
.date_time p {
  font-weight: 600;
  color: rgb(75 85 99);
}
.date_time span {
  white-space: nowrap;
  color: rgb(156 163 175);
}
.booth-status-box {
  padding: 0 20px 5px;
  border-radius: 20px;
  font-size: 12px;
}

.booth-status-container {
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
}

.booth-status-box-color {
  background-color: rgba(37, 138, 37, 0.74);
  color: #ffffff;
}

@media (max-width: 768px) {
  .cardCont {
    flex-direction: column;
  }
  .contentCont h2 {
    font-size: 18px;
    font-weight: 600;
    color: rgb(75 85 99);
  }
  .img_title {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .imgCont {
    width: 100%;
  }
  .img {
    width: 100%;
  }
  .date_timeCont {
    justify-content: center;
  }
}
