.exhibition-payment-modal {
  background-color: rgb(33, 33, 33, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exhibition-payment-modal__container {
  background-color: #ffffff;
  width: 45rem;
  height: 45rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
}

.exhibition-payment-modal__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.exhibition-payment-modal-title {
  color: #046c77;
  font-size: 30px;
  font-weight: 500;
}

.exhibition-payment-modal-value {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.exhibition-payment-modal-value-label {
  font-size: 18px;
  color: #046c77;
}
.exhibition-payment-modal-value-box {
  background-color: #046c77;
  color: #ffffff;
  padding: 0 1rem;
  height: 50px;
  font-size: 30px;
  border-radius: 6px;
}

.exhibition-payment-modal-value-box sub {
  font-size: 20px;
}
.exhibition-payment-modal__close {
  position: absolute;
  right: 1.5rem;
  left: auto;
  top: 1.5rem;
  cursor: pointer;
}

body[dir="rtl"] .exhibition-payment-modal__close {
  left: 1.5rem;
  right: auto;
}

.exhibition-payment-modal__field {
  margin-bottom: 1rem;
}
.exhibition-payment-modal__image {
  position: relative;
}
.exhibition-payment-modal__image img {
  width: 100%;
}

.exhibition-payment-modal__image-download {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 25px;
  background: #e9ecef;
  height: 25px;
  border-radius: 3px;
}

.exhibition-payment-modal__content {
  max-height: 65vh;
  overflow-y: auto;
}

/**** custom scrollbar ****/
.exhibition-payment-modal__content::-webkit-scrollbar {
  width: 8px;
}
.exhibition-payment-modal__content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.exhibition-payment-modal__content::-webkit-scrollbar-thumb {
  background-color: #046c77;
  border-radius: 4px;
}
.exhibition-payment-modal__content::-webkit-scrollbar-thumb:hover {
  background-color: #046c77;
}

.exhibition-payment-modal__actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.exhibition-payment-modal__actions-btn {
  width: 50%;
  background: transparent;
  border-radius: 6px;
  height: 50px;
  padding: 0 1rem;
}
.exhibition-payment-modal__actions-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.exhibition-payment-modal__actions-btn.primary {
  background-color: #046c77;
  color: #ffffff;
}

.exhibition-payment-modal__actions-btn.reject {
  border: 1px solid #ef2929;
  color: #ef2929;
}

@media only screen and (max-width: 768px) {
  .exhibition-payment-modal__container {
    width: 90%;
  }
}

@media only screen and (max-width: 580px) {
  .exhibition-payment-modal-title,
  .exhibition-payment-modal-value-box {
    font-size: 20px;
  }
  .exhibition-payment-modal-value-box sub {
    font-size: 16px;
  }
  .exhibition-payment-modal-value {
    justify-content: space-between;
  }
  .exhibition-payment-modal-value-box {
    height: 40px;
  }
}

.exhibition-payment-modal-status {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.exhibition-payment-modal-status-box {
  padding: 0 20px 5px;
  border-radius: 20px;
  font-size: 12px;
}

.exhibition-payment-modal-status-box.approved {
  background-color: #56b1b7;
  color: #ffffff;
}
.exhibition-payment-modal-status-box.reject {
  background-color: #df4b43;
  color: #ffffff;
}

.exhibition-payment-modal_status-reason {
  color: #56b1b7;
}

.exhibition-payment-modal_status-reason span {
  font-size: 14px;
  color: #066d77;
}
