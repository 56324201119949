/* .css-qbdosj-Input {
  height: 50px !important;
}
.css-1fdsijx-ValueContainer {
  height: 50px !important;
}
.css-hlgwow {
  height: 50px !important;
}
.css-13cymwt-control {
  height: 50px !important;
}
.css-19bb58m {
  height: 50px !important;
} */
.css-13cymwt-control {
  border-color: #ebebeb !important;
}
.css-1u9des2-indicatorSeparator {
  border-color: #ebebeb !important;
  width: 1px !important;
}
