.table-head-actions {
  display: flex;
  gap: 1rem;
}

.table-head-actions__list {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #24b3b9;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
}

.table-head-actions__list-add {
  background-color: #24b3b9;
  border: none;
}
.table-head-actions__list-pdf {
  background-color: #b91414;
  border: none;
}
.table-head-actions__list-xls {
  background-color: #138d5f;
  border: none;
}

.table-head-actions__list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
