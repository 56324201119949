.booth-details {
  width: 90%;
}

.booth-details-title {
  width: 90%;
}

.booth-details-title .table_header {
  padding-bottom: 30px;
}

.add-audio {
  padding: 20px;
  border: 1px dashed #cdcdcd;
  border-radius: 10px;
  font-weight: 700;
  display: inline-block;
  position: relative;
}
.booth-details__file {
  display: flex;
  align-items: center;
  gap: 30px;
}
.add-audio-icon {
  background: #006d77;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  width: 30px;
  bottom: -13px;
  -webkit-transform: translate(-19px);
  transform: translate(-19px);
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}
body[dir="ltr"] .add-audio-icon {
  left: 100%;
}
.add-audio-icon i {
  font-size: 13px !important;
  color: #fff !important;
  transform: unset !important;
  width: auto !important;
  height: auto !important;
  background: none !important;
  padding: 0 !important;
  position: unset !important;
}
.msg-audio {
  font-size: 13px;
  color: #333;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.input-upload-audio {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 22;
  left: 0;
  right: 0;
}

.delete-upload-file,
.delete-upload-file:active,
.delete-upload-file:hover {
  color: #c00;
  border: none;
  background: none;
  padding-top: 35px !important;
}

.delete-upload-file i {
  color: #c00 !important;
}

.booth-booking__actions,
.reject-booth-modal__actions {
  display: flex;
  align-items: center;
  gap: 15px;
}
.reject-booth-modal__actions {
  margin-top: 20px;
}

.booth-booking__actions-reject {
  background-color: transparent;
  border: 1px solid #dc3545;
  color: rgb(51, 51, 51);
}
.booth-booking__actions-confirm-reject {
  background-color: #dc3545;
  color: #ffffff;
}
.booth-booking__actions-confirm-reject[disabled] {
  background-color: #dc3545;
  color: #ffffff;
  opacity: 0.5;
  cursor: not-allowed;
}
.booth-booking__actions-back {
  background-color: transparent;
  border: 1px solid rgb(51, 51, 51);
  color: rgb(51, 51, 51);
}

.reject-booth-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.reject-booth-modal__wrapper {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  width: 500px;
  position: relative;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.reject-booth-modal__header {
  margin-bottom: 20px;
}
