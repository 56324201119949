.link-preview {
  background-color: #24b3b9;
  color: #ffffff;
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  height: 70px;
  display: flex;
  align-items: center;
}
