.FAQ-from{
    margin-bottom: 25px;
}

.FAQ-from select {
    height: 44px;
}
.FAQ-from input[type='text']{
    border: none;
    background: #dddddd;
    height: 34px;
    border-radius: 10px;
    padding: 0 10px;
}
.FAQ-from input[type='text']::after{
    content: "\f002";
}
.FAQ-from form{
    padding-top: 10px;
}
.FAQ-from input[type='submit']{
    padding: 5px 35px;
    color: #fff;
    background: #006D77;
    border: none;
    margin: 0 12px;
    border-radius: 10px;
    cursor: pointer;
}
.box_faq{    
    display: block;
    width: 100%;
    padding: 30px 25px;
    padding-top: 0;
  }
  
  .box_faq .ck.ck-toolbar.ck-toolbar_grouping{
    background: #fff;
    border: none;
  }
  .box_faq .ck.ck-toolbar .ck.ck-toolbar__separator{
    background: #e3e3e3 !important;
  }
  .box_faq .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: transparent;
    background: #f5f5f5;
    }
.box_faq .admin_add_button.btn_zoom{
    margin: 0 !important;
}
  .card-header{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
  }
  .box_faq .card{
    border: none;
    border-bottom: 1px solid #d3d2d2;
    border-radius: 0;
    padding-bottom: 15px;
  }
  .box_faq .card-header{
      padding-left: 0;
      padding-right: 0;
  }
  .box_faq .card-body{
    font-size: 14px;
    color: #727272;
  }
  .table_bank_tran.faq_box_white{
    width: 97%;
    margin: auto;
  }


.faq-add-pg .header_profile {
    background: #011e20;
    padding: 15px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}
.faq-add-pg .profile_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
}
.faq-add-pg .profile_wrapper .profile_grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 50px;
    gap: 50px;
    margin: 20px 0;
}
.faq-add-pg .profile_dhead {
    display: flex;
    align-items: center;
}
.faq-add-pg .profile_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
}
.faq-add-pg .admin_label {
    display: flex;
    margin: 30px 0;
}
.faq-add-pg .admin_add_input {
    display: flex;
    background-color: transparent;
    color: #000;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    position: relative;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 90%;
    height: 44px;
}
.faq-add-pg .FAQ-from input[type=text] {
    border: none;
    background: #ddd;
    height: 34px;
    border-radius: 10px;
    padding: 0 10px;
}
.faq-add-pg .FAQ-from {
    margin-bottom: 25px;
}
.faq-add-pg .table_bank_tran.faq_box_white {
    background-color: #fff;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}
.faq-add-pg .table_header{
    padding: 15px 25px;
}
.faq-add-pg .box_faq textarea.admin_add_input{
    height: 100px;
    border: 1px solid #c5c5c5;
    width: 100%;
  }