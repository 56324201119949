.system-users-list {
  padding: 2rem 0;
}

.system-users-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.system-users-list__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.system-users-list__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.note_table {
  white-space: pre-line;
}

.system-users-list__table__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.system-users-list__table-email__wrapper {
  direction: ltr;
}

.system-users-list__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.system-users-list__table-email:hover {
  color: #24b3b9 !important;
}

.system-users-list__table-jobs {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  width: 225px;
}
.system-users-list__table-jobs-item {
  color: #046c77;
  position: relative;
}

.system-users-list__table-jobs-item:not(:last-of-type)::after {
  content: "";
  position: absolute;
  top: 8px;
  left: -0.5rem;
  width: 1px;
  height: 60%;
  background: #046c77;
}

.system-users-list__table-register-type {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  height: 2.5rem;
  width: 10rem;
  background-color: rgb(36 179 185 / 40%);
  color: #24b3b9;
}
.system-users-list__table-register-type--twitter {
  background-color: rgb(137 228 232 / 40%);
  color: #3dacce;
}
.system-users-list__table-register-type--facebook {
  background-color: rgb(126 153 226 / 40%);
  color: #7e99e2;
}

.system-users-list__table-register-type--linkedIn {
  background-color: rgb(11 51 183 / 40%);
  color: #0b33b7;
}
.system-users-list__table-register-type--google {
  background-color: rgb(185 36 36 / 40%);
  color: #b92424;
}

.reject-payment-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.reject-payment-modal__header h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.reject-payment-modal__header img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.reject-payment-modal__body {
  padding: 1.5rem;
}

.reject-payment-modal__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reject-payment-modal__submit-btn {
  padding: 1rem 6rem;
}

.price-plus {
  color: #24b3b9;
  padding: 0 0.5rem;
  border-radius: 9999px;
  background-color: #24b3b922;
}

.price-minus {
  color: #ff0000;
  padding: 0 0.5rem;
  border-radius: 9999px;
  background-color: #ff000022;
}

.rejection-reason {
  white-space: normal;
  width: 400px;
}

.wallet-transaction-modal {
  width: 100%;
  max-width: 900px;
  height: 95%;
  max-height: 800px;
  overflow-y: auto;
}

.wallet-transaction-modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallet-transaction-modal_header img {
  cursor: pointer;
}

.wallet-transaction_submit_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.wallet-transaction_submit_button {
  padding: 1rem 8rem;
  margin-top: 2rem;
}

.wallet-transaction_fields {
  margin: 0 -15px;
}

.wallet-transaction_field {
  margin-bottom: 1.4rem;
}

.wallet-transaction-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.wallet-transaction-box-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.wallet-transaction-box-options {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.wallet-transaction-box-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 250px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
}

.wallet-transaction-box-option-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 250px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
  background-color: #24b3b9;
  color: #fff;
}

.wallet-transaction-box-option img {
  width: 75px;
}

.wallet-transaction-box-option-selected img {
  filter: brightness(0) invert(1);
}

.wallet-transaction-box-option h4 {
  color: #b1b1b1;
  font-size: 18px;
  font-weight: 400;
}

.wallet-transaction-box-option-amount {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  margin-top: 1rem;
}
