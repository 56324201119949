.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #e8e8e8;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);
  position: relative;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatar__status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.avatar__status-active {
  background-color: #21be3c;
}

.avatar__status-inactive {
  background-color: #b7b7b7;
}
