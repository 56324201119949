.home_course_title {
  font-weight: bold;
  font-size: x-large;
  margin-top: 10px;
  margin-bottom: -4px;
  color: #484e4e;
}
.home_course_title_dep {
  font-size: x-large;
  color: #484e4e;
}
.home_course_desc_dep {
  margin: 15px 0;
  width: 80%;
  color: #acabab;
  line-height: 28px;
}

.home_courses_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.home_courses_title {
  font-weight: bold;
  font-size: x-large;
  color: #484e4e;
}

.main__filter__actions {
  display: flex;
  gap: 1rem;
}

.main__filter__sort-select {
  background-color: #ffffff;
  border-radius: 1.5rem;
  color: #777777;
  padding: 0.5rem 1rem;
  border-width: 2px;
  border-color: #eaebeb;
  width: 100%;
}

.training_specialties {
  margin-top: 20px;
}

.training_specialties_select {
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  color: #24b3b9;
  border: none;
  min-width: 225px;
}

.training_departments {
  margin: 20px 0;
}

.training_departments_select_item {
  background-color: #ddd;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .training_departments_select_item {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    max-width: 150px;
    margin-inline-start: 15px;
  }
}

.training_departments_select_item:hover {
  background-color: #24b3b9;
  color: #ffffff;
}

.training_departments_select_item_active {
  background-color: #24b3b9;
  color: #ffffff;
}

.courses_card_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 2rem;
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
  }
}

.main__filter__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 20px 0;
}

.main__filter {
  display: flex;
  background-color: #ffffff;
  border-radius: 1.5rem;
  color: #777777;
  padding: 0.5rem;
  border-width: 2px;
  border-color: #eaebeb;
  width: 100%;
}
.main__filter__search {
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 10px;
}
.main__filter__search-field {
  width: 100%;
  height: 100%;
  position: relative;
}

.main__filter__search-field input {
  width: 100%;
  height: 100%;
}

.main__filter__search-field__auto-complete {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 500px;
  background-color: #ffffff;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #eaebeb;
  z-index: 100;
  overflow: auto;
}
@media (max-width: 767px) {
  .main__filter__search-field__auto-complete {
    max-height: 300px;
  }
}

.main__filter__search-field__auto-complete ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main__filter__search-field__auto-complete ul li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.main__filter__search-field__auto-complete ul li:hover {
  background-color: #f5f5f5;
}

.main__filter__search-btn {
  background-color: #24b3b9;
  padding: 0.7rem 3rem;
  border-radius: 1rem;
  color: #ffffff;
  font-weight: 600;
}

.main__filter__rest-btn {
  background-color: #ddd;
  border-radius: 9999px;
  padding: 0.4rem 0.8rem;
  color: #484e4e;
}

.no_courses {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #24b3b9;
}

@media only screen and (max-width: 992px) {
  .main__filter {
    width: 100%;
  }
}

.department_container {
  margin-bottom: 35px;
}
.training-course-back {
  display: flex;
  gap: 3px;
  cursor: pointer;
  min-width: fit-content;
}

.training-course-back-icon {
  width: 20px;
  height: 20px;
  margin-top: 0.4rem;
}

.training-course-back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body[dir="ltr"] .training-course-back-icon img {
  transform: rotate(180deg);
}

.training-course-back-text {
  font-size: 16px;

  color: #24b3b9;
  padding-bottom: 0.4rem;
}
.btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
