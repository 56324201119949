.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}

.training_details {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 2rem;
  align-items: flex-start;
}

.training_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.training_title {
  font-size: 27px;
  margin-bottom: 20px;
  display: flex;
}

.training_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106%;
}

.training_text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 50px;
  font-size: 16px;
}

.circle_progress_training {
  width: 70%;
}
.training_card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 70px;
  font-size: 14px;
  width: 100%;
  padding: 10px 5px;
  border-radius: 13px;
}

.training_card_time {
  padding: 0 12px;
}

.training_line {
  border-left: 2px solid rgb(225 225 225 / 28%);
  height: 10px;
}

.training_level {
  font-size: 14px;
}

.training_level_in {
  font-size: 18px;
}

.training_progress {
  display: flex;
}

.training_dprogress {
  position: relative;
  background: #294351;
  border-radius: 25px;
  width: 17px;
  margin: 0 2px;
  height: 10px;
}

.training_dprogress.active {
  background: #22b3b9;
}

.training_btn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.training_inside_btn {
  width: 100%;
  height: 50px;
  font-size: 18px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
}
.training-details .training_inside_btn {
  width: auto;
  padding: 10px 40px 10px 30px;
  height: auto;
  line-height: 23px;
  position: relative;
}
body[dir="rtl"] .training-details .training_inside_btn {
  padding: 10px 30px 10px 40px;
}
.training-details .training_inside_btn::before {
  content: "\f054";
  font-family: "FontAwesome";
  position: absolute;
  top: 15px;
  font-size: 11px;
  right: 13px;
  z-index: 2;
  padding: 0 10px;
  pointer-events: none;
  cursor: pointer;
}
body[dir="rtl"] .training-details .training_inside_btn::before {
  content: "\f053";
  left: 13px;
  right: unset;
}

.training_up_level {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 70px;
  font-size: 14px;
  width: 100%;
  padding: 0 30px;
  border-radius: 13px;
}

/* .CircularProgressbar {
  display: flex;
  justify-content: center;
  width: 70px !important;
  margin: 0 10px;
  padding: 0;
} */

@media (max-width: 991px) {
  .training_head {
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .training_details {
    display: flex;
    flex-direction: column;
  }

  .training_text {
    font-size: 14px;
  }

  .training_card {
    font-size: 10px;
    padding: 10px 0;
  }

  .training_level_in {
    font-size: 14px;
  }

  .training_inside_btn {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
}
