.courses_card {
  width: 100%;
  height: 575px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
  background-color: #fff;
}

.courses_card_first {
  background-image: url("../../../../assets/image/doctor.png");
  background-color: #ccc;
  object-fit: cover;
  background-position: top;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}

.courses_name {
  width: 100%;
  background-color: rgb(3 41 43 / 90%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  transition: all 0.5s;
  padding-bottom: 20px;
  padding: 20px 10px;
  text-align: initial;
  text-align: left;
}
body[dir="rtl"] .courses_name {
  text-align: right;
}
.courses_name .courses_name_text {
  width: 100%;
}

.logo_hover {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: flex-start;

}

.courses_name:hover {
  height: 100%;
  background-size: 100px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  float: right !important;
}

.courses_name:hover > .logo_hover {
  display: flex;
  opacity: 1;
  width: 100%;
  margin-top: -70px;
  margin-bottom: 70px;
  justify-content: flex-start;
}

.logo_hover_width {
  width: 100px;
}

.courses_card_second {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 70px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #ccc;
  font-size: 14px !important;
  gap: 5px;
}

.courses_card_category {
  font-size: 16px;
}

.courses_card_profile {
  display: flex;
  align-items: center;
}

.courses_card_icon {
  background-image: url("../../../../assets/image/smallDoc.png");
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.courses_card_name {
  display: flex;
  align-items: center;
  flex: 1;
}

.courses_card_third {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-size: 14px;
}

.courses_card_profile {
  display: flex;
  align-items: center;
}

.img_hour {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px 5px 0;
}

.courses_card_button {
  margin: 0 15px;
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.courses_card_grid .courses_card_button_in_link {
  outline: none;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courses_card_button a.courses_card_button_in,
.courses_card_button .courses_card_button_in,
.programs_card_button .programs_card_button_in {
  border: none !important;
}
.courses_card_button_in {
  outline: none;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses_card_grid .courses_card_button_in_link:hover,
.courses_card_grid .courses_card_button a:hover,
.courses_card_grid .courses_card_button_in:hover {
  background-color: #006d77;
  color: #fff;
  border: 2px solid #006d77;
}

@media (max-width: 1199px) {
  .courses_card_second {
    padding: 0 15px;
    font-size: 14px;
  }
  .courses_card_third {
    padding: 0 15px;
  }

  .courses_card_category {
    font-size: 14px;
  }

  .courses_card_third {
    font-size: 14px;
  }

  .courses_card_button {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .courses_card {
    height: 575px;
  }

  .courses_card_second {
    padding: 0 10px;
    font-size: 13px;
  }
  .courses_card_third {
    padding: 0 10px;
  }

  .courses_card_category {
    font-size: 12px;
  }

  .courses_card_third {
    font-size: 12px;
  }

  .courses_card_button {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .courses_card {
    height: 575px;
  }

  .courses_card_second {
    padding: 0 10px;
    font-size: 12px;
  }

  .courses_card_button {
    height: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .courses_card_second {
    font-size: 11px;
  }
}
