.mainNav {
  display: flex;
  justify-content: space-between;
}
.image_mobile {
  max-width: 300px;
  width: 300px;
}

.imageContMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.navbar_logo_main {
  display: flex;
  gap: 10px;
  align-items: center;
}
.hamburger-pointer {
  cursor: pointer;
  margin: 0 10px;
}
.flex_menu {
  display: flex;
  align-items: center !important;
}
.image_logo_new {
  max-width: 375px;
  width: 375px;
}

@media (max-width: 1250px) {
  .image_logo_new {
    max-width: 320px;
    width: 320px;
  }
}
@media (max-width: 960px) {
  .image_logo_new {
    max-width: 290px;
    width: 290px;
  }
}
@media (max-width: 821px) {
  .image_logo_new {
    max-width: 200px;
    width: 200px;
  }
}
@media (max-width: 556px) {
  .image_logo_new {
    display: none;
  }
}
.backdrop_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
}
.header_shadow {
  background-color: #fff;
  box-shadow: 0 0 21px #aaa;
  height: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1050px) {
  .header_shadow {
    height: 170px;
  }
  .imageContMobile {
    margin-bottom: 20px;
  }
}
