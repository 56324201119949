.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-border-radius: 50%;
}
.notification.dropdown-toggle::after {
  display: none;
}
.header-dropdown.notification {
  font-size: 25px;
}
.notification-recently-admim {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #cc0000;
  border-radius: 50%;
  right: 10px;
  border: 2px solid #fff;
  top: 25px;
}
.notification-recently {
  position: absolute;
  width: 11px;
  height: 11px;
  background: #cc0000;
  border-radius: 50%;
  right: 10px;
  border: 2px solid #fff;
  top: 25px;
}
body[dir="ltr"] .notification-recently {
  position: absolute;
  width: 11px;
  height: 11px;
  background: #cc0000;
  border-radius: 50%;
  right: 23px;
  border: 2px solid #fff;
  top: 25px;
}

.dropdown-menu.notifications-box {
  position: absolute;

  z-index: 1000;
  /* min-width: 31.5rem; */
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: inherit;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 10px 0;
  border: none;
  box-shadow: 0px 1px 5px #d0d0d0;
  padding-bottom: 0;
  max-height: 400px;
  right: 0px;
  left: unset;
}
body[dir="rtl"] .dropdown-menu.notifications-box {
  left: 10px;
  right: unset;
}
.notifications-box.scroll-y {
  overflow-y: scroll;
}
.notifications-box h4 {
  padding: 0px 20px 10px 20px;
}
.notifications-box .box-notify {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.notifications-box .box-notify.new,
.notifications-box .box-notify.new:hover {
  background-color: rgb(0 255 255 / 38%);
}
.notifications-box .box-notify:hover {
  background-color: #f2f2f2;
}
.notifications-box .type-time {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .admin_img {
    width: 30px;
    height: 30px;
  }

  .dropdown-menu.notifications-box {
    position: fixed;
    left: 15px !important;
    right: 15px !important;
    top: 0;
  }
  /* body[dir="ltr"] .dropdown-menu {
    left: unset !important;
    right: 0 !important;
  } */
}
.btn:focus {
  box-shadow: none !important;
}
