.sectionTitle {
  color: #006d77;
}
.sectionTitle span {
  color: #f83800;
}
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 2rem 0; */
  gap: 2rem;
  padding: 0.5rem 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.categories h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.categories p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #7a7a7a;
}

.slider {
  width: 100%;
  display: flex;
  gap: 1rem;
  min-height: 20rem;
  overflow: none;
  padding: 2rem 0;
}
.trainersSectionCont {
  /* margin: 2rem 0; */
  padding: 4rem 0;

  background: #e7e7e7;
}

.staticCont {
  display: flex;
  gap: 5rem;
  justify-content: center;
}
