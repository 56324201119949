.back {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body[dir="ltr"] .back-icon img {
  transform: scale(-1);
}

.back-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  padding-bottom: 0.4rem;
}

.create_tax__form__field-actions-submit {
  width: 250px;
}
@media only screen and (max-width: 480px) {
  .create_tax__form__field-actions-submit {
    width: 100%;
  }
}
.create_tax__form__field-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.create_tax__form__field-actions button {
  background-color: #046c77dd;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exam_result__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.exam_result {
  padding: 2rem 0;
}
.modal__icon img {
  width: 80px;
}
