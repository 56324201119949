.search_container {
  display: flex;
  gap: 20px;
  /* flex: 3 1 auto; */
}
.search-box {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 250px;
  background: linear-gradient(
    90deg,
    rgba(233, 229, 229, 0.555) 25%,
    #006d7748 93%
  );
  padding: 5px 25px;
  border-radius: 16px;
  color: #006d77;
  /* box-shadow: 2px 2px 10px #ddd; */
}
body[dir="rtl"] .search-box {
  background: linear-gradient(
    90deg,
    #006d7748 25%,
    rgba(233, 229, 229, 0.555) 93%
  );
}
@media (max-width: 710px) {
  .search-box {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 960px) {
  .search-box {
    width: 190px;
  }
}
@media (max-width: 821px) {
  .search-box {
    width: 160px;
  }
}
@media (max-width: 710px) {
  .search-box {
    width: 100%;
  }
}
.main__filter__search-field__auto-complete {
  position: absolute;
  top: 80%;
  /* left: 0; */
  width: 250px;
  max-height: 500px;
  background-color: #ffffff;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #eaebeb;
  z-index: 1000;
  overflow: auto;
}

.main__filter__search-field__auto-complete ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main__filter__search-field__auto-complete ul li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.main__filter__search-field__auto-complete ul li:hover {
  background-color: #f5f5f5;
}
