:root {
  --color-main-title: #066d77;
}

.breadcrumbs-my-30 {
  padding-top: 45px;
  padding-bottom: 45px;
}

/* YourComponent.css */

.image-container {
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  width: 100%;
}

.image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* img {
  max-width: 100%;
  vertical-align: middle;
} */

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45%;
  background: linear-gradient(360deg, rgb(129 129 129 / 69%) 0%, rgb(155 155 155 / 3%) 100%);
  z-index: 1;
}

.content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  color: white;
  padding: 0 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-title {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  margin-inline-end: 10px;
  max-width: 60%;
}

.image-description {
  margin: 0;
  padding: 5px 20px;
  font-size: 0.8rem;
  background: #24b3b9;
  border-radius: 30px;
}

.rate {
  display: flex;
}

.rate p {
  margin-bottom: 0;
  margin: 0 10px;
}

.info-content {
  display: flex;
  align-items: center;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-inline-start: 25px;
}

.list-buttons {
  display: flex;
}

.list-buttons .accepted {
  padding: 10px 35px;
  border-radius: 50px;
  background-color: #24b3b9;
  color: #fff;
  cursor: pointer;
  margin-inline-end: 20px;
}

.list-buttons .accepted[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.list-buttons .rejected {
  padding: 10px 35px;
  border-radius: 50px;
  background-color: #fff;
  color: #24b3b9;
  cursor: pointer;
}

.list-buttons .rejected[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-60 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.boot_payment_response {
  display: flex;
  justify-content: space-between;
}

.boot_payment_response .full_card {
  width: 48%;
}

.boot_payment_response p {
  margin-bottom: 0;
}

.card_response {
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding: 30px 24px;
  border-radius: 10px;
  /* width: 40%; */
}

.card_response p {
  color: #046c77;
}

.card_payment {
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
  border-radius: 10px;
}

.card_response img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-inline-end: 40px;
}

.card_payment img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.card_payment p {
  margin: 0 22px;
  font-size: 17px;
  color: #24b3b9;
  /* font-weight: 400; */
}
.card_payment svg {
  width: 25px;
  height: 25px;
}

.card_payment-label {
  border-radius: 20px;
  border: #24b3b9 1px solid;
  padding: 10px 25px;
  color: #24b3b9;
  cursor: pointer;
  transition: 0.3s all;
}
.card_payment-details {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.d-flex- {
  display: flex;
  align-items: center;
}

.avatar {
}

/*  */

.boot-details__pref {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.boot-details__pref-title {
  color: #046c77;
  font-size: 24px;
  font-weight: bold;
}

.boot-details__pref-desc {
  color: #7b7b7b;
  font-size: 16px;
  text-align: justify;
  margin-bottom: 40px;
}

/* boot-details__audios */

.boot-details__audios {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.boot-details__audios-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.boot-details__audios-item-title {
  font-size: 16px;
  color: #066d77;
}

.boot-details__audios-item audio {
  width: 100%;
}

/* details__carousel */

.boot-details__carousel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 50px;
  padding-bottom: 50px;
}

.boot-details__carousel-title {
  color: #777777;
  font-size: 20px;
}

/* Table */

.boot-details__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.boot-details__tables__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boot-details__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.boot-details__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.boot-details__table-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
}

.boot-details__table-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.boot-details__table__users__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  padding: 0 1rem;
}

/* carousel-slide */

.carousel-slide {
  background-color: #24b3b9;
  padding: 30px 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #fff;
}

/* stars rating */

.booth-status {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.booth-status-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.booth-status-box {
  padding: 0 20px 5px;
  border-radius: 20px;
  font-size: 12px;
}

.booth-status-box-active {
  background-color: #56b1b7;
  color: #ffffff;
}

.booth-status-box-inactive {
  background-color: #df4b43;
  color: #ffffff;
}

.booth-status-box-not-complete {
  background-color: #c8c8c8;
  color: #ffffff;
}
.booth-status-box-waiting {
  background-color: #b5b845;
  color: #ffffff;
}

.visits-table {
  margin-top: 8rem;
}
