.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #202124 !important;
  /* width: 35%;
  height: 15%; */
  width: 40px;
  height: 40px;
  background-origin: content-box;
  border-radius: 50% !important;
  padding: 15px;
  background-size: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  /* height: 80px; */
  width: 75px;
}
