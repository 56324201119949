.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgb(33 33 33 / 75%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  /* width: 34rem;
  padding: 2.5rem; */
  width: 25rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  align-items: center;
}

.modal__content {
  text-align: center;
  padding: 2px 0 6px;
}

.modal__icon img {
  width: 80px;
}

.modal__content__title {
  font-size: 20px;
  color: #555555;
  font-weight: 500;
  line-height: 1.5;
}

.modal__content__name {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  /* word-break: break-all; */
}

.modal__content__confirm-msg {
  font-size: 19px;
  color: #555555;
  font-weight: 400;
  line-height: 1.5;
}

.modal__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.modal__buttons button {
  border: none;
  border-radius: 6px;
  height: 50px;
  padding: 0 1rem;
  width: 130px;
  font-size: 16px;
}

.modal__buttons__cancel {
  background-color: #cfcfcf;
  color: #858585;
}

.modal__buttons__confirm {
  /* background-color: #e62626; */
  color: #ffffff;
}

.modal__buttons__confirm[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .modal__container {
    width: 90%;
  }

  .modal__content__title,
  .modal__content__confirm-msg {
    font-size: 16px;
  }

  .modal__content__name {
    font-size: 14px;
  }

  .modal__buttons button {
    width: 110px;
  }
}
