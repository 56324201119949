.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
}

.container-fluid {
  /* flex-direction: column;
  width: 100%;
  display: none; */
  max-width: 1280px;
  padding: 0 20px;
}

.min-hight-78 {
  min-height: calc(78vh - 20px);
}

.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.content-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 55px;
}

@media (max-width: 991px) {
  .content-flex {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
}
